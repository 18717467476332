import React from "react";
import Papa from "papaparse";

const CsvToTable = ({ csvData }) => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    Papa.parse(csvData, {
      header: true,
      dynamicTyping: false,
      complete: (result) => {
        const cleanedData = result?.data?.filter((obj) =>
          !(
            (obj.hasOwnProperty("") && obj[""] === "") ||
            (Object.keys(obj).length === 1 && Object.values(obj)[0] === "")
          )
        );
        setData(cleanedData);
      },
    });
  }, [csvData]);

  return (
    <table className="table table-striped table-hover table-bordered">
      <thead>
        <tr>{data.length > 0 && Object.keys(data[0]).map((key, index) => <th key={index}>{key}</th>)}</tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {Object.values(row).map((val, colIndex) => (
              <td key={colIndex}>{val}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CsvToTable;
