import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

const FormInput = ({
  label,
  name,
  placeholder,
  classInput,
  classGroup,
  register,
  errors,
  required,
  requiredMessage,
  pattern,
  maxLength,
  maxLengthMessage,
  min,
  minEqual,
  minMessage,
  max,
  maxEqual,
  maxMessage,
  type,
  range,
  rangeMessage,
  disabled,
  valueGreaterThanZero,
  valuePositive,
  errorClass,
  unit,
  errorsRelative = false,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={classNames("form-group position-relative", classGroup)}>
      {label && (
        <Label for={name} className={classNames("position-relative", { "required-input": required })}>
          {label} {unit && `(${unit})`}
        </Label>
      )}
      <input
        id={name}
        placeholder={placeholder}
        className={classNames("form-control", classInput, {
          "border-danger": errors,
        })}
        onKeyPress={(event) => {
          if (type === "number" && !/^-?\d*\.?\d*$/.test(event.key)) {
            event.preventDefault();
          }
        }}
        {...register(name, {
          ...(required && { required: requiredMessage || t("required") }),
          ...(maxLength && { maxLength: { value: maxLength, message: maxLengthMessage || t("name_maxlength") } }),
          validate: {
            ...(required && { requiredTrimmed: (value) => value?.toString()?.trim?.().length > 0 || requiredMessage || t("required") }),
            ...(pattern && {
              pattern: (value) => pattern?.value?.test(value) || pattern?.message || t("invalid_format"),
            }),
            ...(min && { min: (value) => parseFloat(value) > parseFloat(min) || minMessage }),
            ...(minEqual && { min: (value) => parseFloat(value) >= parseFloat(minEqual) || minMessage }),
            ...(max && { max: (value) => parseFloat(value) < parseFloat(max) || maxMessage }),
            ...(maxEqual && { max: (value) => parseFloat(value) <= parseFloat(maxEqual) || maxMessage }),
            ...(valueGreaterThanZero && { greaterThan0: (value) => parseFloat(value) > 0 || t("greater_than_zero") }),
            ...(valuePositive && { positive: (value) => parseFloat(value) >= 0 || t("value_positive") }),
            ...(range && {
              range: (value) => (parseFloat(value) <= parseFloat(range.to) && parseFloat(value) >= parseFloat(range.from)) || rangeMessage,
            }),
          },
        })}
        disabled={disabled}
      />
      {errors && <div className={`text-danger font-12 ${errorsRelative ? "" : "position-absolute"} ${errorClass}`}>{errors}</div>}
    </div>
  );
};

export default FormInput;
