import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getConfigFileName, getFileName } from "src/utils/common";
import { deleteTestAnalysisItem } from "src/services/nal-test-analysis";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";

const ModalDeleteCsv = ({ toggle, closeModal, data, onDelete }) => {
  const { t } = useTranslation("wind-tunnel-test-nal");
  const showAlert = useSetRecoilState(alertState);
  const [submitted, setSubmitted] = useState(false);

  const handleDeleteFile = async () => {
    try {
      setSubmitted(true);
      await deleteTestAnalysisItem(data.id);
      await onDelete();
      closeModal();
      setSubmitted(false);
      showAlert({
        status: true,
        message: t("new.delete_success"),
        type: "success",
      });
    } catch (e) {
      closeModal();
      setSubmitted(false);
      showAlert({
        status: true,
        message: e?.response?.data?.error?.message,
        type: "danger",
      });
    }
  };
  return (
    <Modal centered isOpen={toggle} toggle={closeModal} backdrop="static">
      <ModalHeader>{t("new.delete_modal.title")}</ModalHeader>
      <ModalBody>
        <div className="text-break">{data?.calculated_path ? getFileName(data.calculated_path) : getConfigFileName(data?.config)}</div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-sm btn-secondary" disabled={submitted} onClick={closeModal}>
          {t("new.delete_modal.cancel")}
        </button>
        <button className="btn btn-sm btn-danger" disabled={submitted} onClick={handleDeleteFile}>
          {t("new.delete_modal.delete")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDeleteCsv;
