import React, { useEffect, useRef, useState } from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { useHover } from "ahooks";
import { GET_LABEL_WITH_STATUS, PROCESS_STATUS, PROCESS_STATUS_NAME } from "src/constant/common";
import { getContentFromLink } from "src/services/simulator-flight";
import "react-tooltip/dist/react-tooltip.css";

const LabelStatus = ({ row, field = "status", staticTooltip, errorList, containerClass }) => {
  const labelRef = useRef();
  const isHovering = useHover(labelRef);
  const [tooltip, setTooltip] = useState("");

  const getFinalStatus = (simulationData) => {
    if (simulationData?.has_validation_error && (simulationData[field] === PROCESS_STATUS.SUCCEEDED || simulationData[field] === PROCESS_STATUS.SUCCESSFUL)) {
      return PROCESS_STATUS.FAILED;
    }
    return simulationData[field];
  }

  useEffect(() => {
    if (
      isHovering &&
      getFinalStatus(row) === PROCESS_STATUS.FAILED &&
      staticTooltip
    ) {
      setTooltip(staticTooltip);
    }
    if (
      isHovering &&
      getFinalStatus(row) === PROCESS_STATUS.FAILED &&
      row?.error_log_url
    ) {

      getContentFromLink(row.error_log_url).then((res) => {
        let errorData = '• ' + res.data;
        if (errorList?.length) {
          errorData += '\n' + errorList.join('\n');
        }
        setTooltip(errorData);
      });
    }
    if (!row?.error_log_url && errorList?.length) {
      setTooltip(errorList.join('\n'));
    }
  }, [isHovering]);

  useEffect(() => {
    return setTooltip('');
  }, []);

  return (
    <div ref={labelRef} className={containerClass}>
      <Badge
        color={GET_LABEL_WITH_STATUS[getFinalStatus(row)]}
        className="p-2 width-by-px-110 height-by-px-36 f-s-13"
        id={`status-${row?.id}`}
      >
        {PROCESS_STATUS_NAME[getFinalStatus(row)]}
      </Badge>
      {(getFinalStatus(row) === PROCESS_STATUS.FAILED && tooltip) && (
        <UncontrolledTooltip target={`status-${row?.id}`}>{tooltip}</UncontrolledTooltip>
      )}
    </div>
  );
};

LabelStatus.propTypes = {};

export default LabelStatus;
