import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader } from "reactstrap";
import useUrlState from "@ahooksjs/use-url-state";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getSimulators, getSeriesInput, getContentFromLink, getDashboardLink } from "src/services/simulator-gnc";
import { DASHBOARD_INPUT_TYPE, DASHBOARD_TYPE, PAGINATION_DEFAULT, REFETCH_INTERVAL_TIME } from "src/constant/common";
import SearchInput from "src/components/Common/SearchInput";
import { getFileName, sortData, uniqArray, handleScrollTo, handleScrollToTop } from "src/utils/common";
import { PROCESS_STATUS, DASHBOARD_STATUS, RETRY_TIME, ERROR_CODE_BY_TYPE, SUB_TEXT_BY_ERROR_TYPE } from "src/constant/common";
import APP_ROUTES from "src/routes/routes";
import { useMutation, useQuery } from "src/Hooks/useReactQuery";
import LabelStatus from "src/components/Common/LabelStatus";
import { useRecoilState, useSetRecoilState } from "recoil";
import { alertState, resetFormPromptState } from "src/recoil/atoms/global";
import ModalSeriesFilesView from "src/components/Common/Modal/ModalSerieFilesView";
import {
  ButtonCollapse,
  ButtonDownload,
  ButtonCopy,
  ButtonRetry,
  SingleDashboard,
  ButtonResultDisplay,
} from "src/components/Common/Button";
import ShowDataCell from "src/components/Common/DataCell";
import SeriesData from "../components/SeriesData";
import TableCommon from "src/components/Common/Table";
import ModalCaseResult from "../components/ModalCaseResult";

const GNCSimulator = () => {
  const { t } = useTranslation(["gnc-simulator", "common"]);

  const showAlert = useSetRecoilState(alertState);
  const [resetForm, setResetForm] = useRecoilState(resetFormPromptState);

  const [params, setParams] = useUrlState(
    {},
    {
      navigateMode: "replace",
      stringifyOptions: {
        skipNull: true,
        skipEmptyString: true,
      },
    }
  );

  const [textSearch, setTextSearch] = useState(params?.condition || null);

  const [seriesById, setSeriesById] = useState();
  const [isLoadingSimulator, setIsLoadingSimulator] = useState(true);
  const [simulationIdsOpen, setSimulationIdsOpen] = useState([]);
  const [simulationIsUpdated, setSimulationIsUpdated] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState({});
  const [allowScrollToId, setAllowScrollToId] = useState(true);
  const [dataModalFileView, setDataModalFileView] = useState({ isOpen: false, seriesData: {}, fileData: {} });
  const [caseResult, setCaseResult] = useState(null);

  const { data: dataSimulator, refetch } = useQuery(
    ["getGNCSimulators"],
    () =>
      getSimulators({
        page: params.page || 1,
        limit: PAGINATION_DEFAULT.pageSize,
        q: params?.condition,
      }),
    {
      onSuccess: (data) => {
        if (params?.id && allowScrollToId) {
          handleScrollTo(0, 90, `row${params.id}`);
          setAllowScrollToId(false);
        }
        for (const simulationItem of data.items) {
          if (
            simulationIdsOpen.some((id) => id === simulationItem.id) &&
            simulationItem.status === PROCESS_STATUS.FAILED &&
            !simulationIsUpdated.some((id) => id === simulationItem.id)
          ) {
            getSeriesDetail(simulationItem);
            setSimulationIsUpdated((oldVal) => [...oldVal, simulationItem.id]);
          }
        }
      },
      onSettled: () => setIsLoadingSimulator(false),
      refetchInterval: REFETCH_INTERVAL_TIME,
    }
  );
  const { mutateAsync: getSeriesData } = useMutation(
    {
      mutationKey: (id) => `getGNCSeries${id}`,
      mutationFn: (id) => getSeriesInput(id),
    },
    true
  );

  const columns = [
    {
      dataField: "id",
      text: t("list.table.id"),
      headerClass: "width-by-px-120",
    },
    {
      dataField: "name",
      text: t("list.table.name"),
      headerClass: "width-by-percent-30",
      formatter: (name) => <ShowDataCell data={name} />,
    },
    {
      dataField: "status",
      text: t("list.table.status"),
      headerClass: "width-by-px-150",
      formatter: (_, row) => <LabelStatus row={row} errorList={getErrorDetail(row?.validate_error?.errors, row?.validate_error?.name)} />,
    },
    {
      dataField: "param",
      text: t("list.table.param"),
      headerClass: "width-by-px-80",
      formatter: (_, row, { isCollapse, toggleCollapse }) => (
        <ButtonCollapse handleCollapse={() => toggleCollapse(() => onClickCollapsed(row))} isCollapsed={!isCollapse} />
      ),
      collapseRow: (row) => (
        <SeriesData
          simulationData={row}
          isError={seriesById?.[row?.id]?.error}
          isLoading={seriesById?.[row?.id]?.loading}
          serieData={seriesById?.[row?.id]}
          handleViewFile={setDataModalFileView}
          updateData={setSeriesById}
        />
      ),
    },
    {
      dataField: "version_name",
      text: t("list.table.version"),
      headerClass: "width-by-percent-20",
    },
    {
      dataField: "updated_at",
      text: t("list.table.update_at"),
      headerClass: "width-by-percent-20",
    },
    {
      dataField: "updated_by",
      text: t("list.table.user"),
      headerClass: "width-by-percent-25",
    },
    {
      dataField: "note",
      text: t("list.table.note"),
      headerClass: "width-by-percent-25",
      formatter: (note) => <ShowDataCell data={note} />,
    },
    {
      dataField: "",
      text: "",
      headerClass: "width-by-px-60",
      formatter: (_, row) => (
        <SingleDashboard
          id={row.id}
          isShow={row.status === PROCESS_STATUS.SUCCEEDED}
          isLoading={dashboardLoading?.[row?.id]}
          handleClick={() => handleClickDashboard(row)}
          isReady={!!row.simulator_dashboard?.dashboard_url}
        />
      ),
    },
    {
      dataField: "",
      text: "",
      headerClass: "width-by-px-60",
      formatter: (_, row) => (
        <ButtonResultDisplay handleClick={() => setCaseResult(row?.id)} id={row.id} isShow={row.status === PROCESS_STATUS.SUCCEEDED} />
      ),
    },
    {
      dataField: "",
      text: "",
      headerClass: "width-by-px-60",
      formatter: (_, row) => <ButtonDownload url={row.result_url} id={row.id} />,
    },
    {
      dataField: "",
      text: "",
      headerClass: "width-by-px-60",
      formatter: (_, row) => (
        <>
          <ButtonCopy
            id={row.id}
            isShow={row.status === PROCESS_STATUS.SUCCEEDED}
            toUrl={`/gnc-simulator/${row?.id}?action=copy${params.page ? "&page=" + params.page : ""}${
              params?.condition ? "&condition=" + params.condition : ""
            }`}
          />
          <ButtonRetry
            id={row.id}
            isShow={row.status === PROCESS_STATUS.FAILED}
            toUrl={`/gnc-simulator/${row?.id}?action=retry${params.page ? "&page=" + params.page : ""}${
              params?.condition ? "&condition=" + params.condition : ""
            }`}
          />
        </>
      ),
    },
  ];

  const columnsSeriesTable = [
    {
      dataField: "param_filename",
      text: "param_filename",
    },
  ];

  const convertDataSeries = (data) => {
    let columnsName = [];
    const dataSeries = data.map((series) => {
      let seriesItem = {};
      const { param_filename, serie_file_name, serie_name, jsonObject } = series;
      let columnsData = {};

      if (series.jsonObject?.parameter) {
        seriesItem = { ...seriesItem, ...series.jsonObject?.parameter };
      }

      // if (series.jsonObject?.settings) {
      //   seriesItem = { ...seriesItem, ...series.jsonObject?.settings };
      // }

      Object.keys(seriesItem).forEach((field) => {
        columnsData = {
          ...columnsData,
          [field]:
            Array.isArray(seriesItem[field]?.value) && seriesItem[field]?.value?.length > 0
              ? [...seriesItem[field]?.value].join(", ")
              : seriesItem[field]?.value,
        };

        columnsName.push({
          dataField: `${field}`,
          text: `${field} ${seriesItem[field]?.unit ? `(${seriesItem[field]?.unit})` : ""}`,
        });
      });

      return {
        ...series,
        paramList: { param_filename, series: serie_name, ...columnsData },
        name: serie_file_name,
        fileContent: jsonObject,
        errorList: series?.errors?.length ? getErrorDetail(series.errors, serie_file_name) : [],
      };
    });

    return {
      dataSeries: sortData(sortData(dataSeries, "param_filename"), "serie_name"),
      columnSeries: uniqArray([...columnsSeriesTable, ...uniqArray(columnsName)]),
    };
  };

  const getSeriesDetail = async (simulationData) => {
    const { id } = simulationData;
    try {
      const data = await getSeriesData(id);
      const json = [];
      let countError = 0;
      await Promise.all(
        [...data].map((i) =>
          getContentFromLink(i?.link)
            .then((res) => {
              json.push({ ...i, param_filename: getFileName(i?.serie_file_name), jsonObject: res.data });
            })
            .catch(() => {
              countError += 1;
              showAlert({ status: true, message: t("file_error"), type: "danger" });
            })
        )
      );
      if (countError === data.length) {
        setSeriesById((oldVal) => ({
          ...oldVal,
          [id]: {
            data: oldVal?.[id]?.data || [],
            columns: oldVal?.[id]?.columns || [],
            loading: false,
            error: !!!oldVal?.[id]?.data?.length,
          },
        }));
        return;
      }
      const seriesData = convertDataSeries(json);
      setSeriesById((oldVal) => ({
        ...oldVal,
        [id]: {
          data: seriesData.dataSeries,
          columns: seriesData.columnSeries,
          loading: false,
          error: false,
        },
      }));
    } catch {
      setSeriesById((oldVal) => ({
        ...oldVal,
        [id]: {
          data: oldVal?.[id]?.data || [],
          columns: oldVal?.[id]?.columns || [],
          loading: false,
          error: !!!oldVal?.[id]?.data?.length,
        },
      }));
    }
  };

  const onClickCollapsed = (row) => {
    const id = row?.id;
    if (simulationIdsOpen.some((id) => id === row.id)) {
      setSimulationIdsOpen((oldVal) => [...oldVal.filter((id) => id !== row.id)]);
      return;
    }
    setSimulationIdsOpen((oldVal) => [...oldVal, row.id]);
    setSeriesById((oldVal) => ({
      ...oldVal,
      [id]: {
        ...oldVal?.[id],
        loading: true,
      },
    }));
    getSeriesDetail(row);
  };

  const getFieldFromErrorData = (errFields = []) => {
    let fields = errFields.filter((item) => !String(item).includes("list[") && !String(item).includes("literal["));
    if (fields.some((field) => Number.isInteger(field))) {
      fields = fields.map((field) => (Number.isInteger(field) ? `[${field}]` : field)).join(".");
      fields = fields.replace(/\.\[/g, "[");
    } else {
      fields = fields.join(".");
    }
    return fields;
  };

  const getErrorDetail = (errList, fileName) => {
    if (!errList?.length) {
      return [];
    }
    let outputList = [];
    const groupByErrorCode = {};
    for (const errItem of errList) {
      const errCode = ERROR_CODE_BY_TYPE?.[errItem.type] || "FE-0005";
      groupByErrorCode[errCode] = groupByErrorCode?.[errCode] || { [errItem.type]: [] };
      groupByErrorCode[errCode][errItem.type] = groupByErrorCode?.[errCode]?.[errItem.type] || [];
      groupByErrorCode[errCode][errItem.type].push(errItem);
    }

    for (const errCode in groupByErrorCode) {
      let errMsgItem = "• " + t(`error.${errCode}-title`);
      const errGroup = groupByErrorCode[errCode];

      switch (errCode) {
        case "FE-0007":
          for (const type in errGroup) {
            for (const errItem of errGroup[type]) {
              const fieldName1 = getFieldFromErrorData(errItem?.fields?.[0]);
              const fieldName2 = getFieldFromErrorData(errItem?.fields?.[1]);
              errMsgItem += t(`error.${errCode}-content`, {
                field_name_1: fieldName1,
                field_name_2: fieldName2,
                file_name: getFileName(fileName),
              });
            }
          }
          break;
        default:
          for (const type in errGroup) {
            const fields = [...new Set(errGroup[type].map((item) => getFieldFromErrorData(item.loc)))].join(", ");
            const descMsg = errGroup[type][0]?.msg || "";
            const min = errGroup[type][0]?.ctx?.min_length;
            const max = errGroup[type][0]?.ctx?.max_length;
            const limitVal = min ? `least \`${min}\`` : max ? `most \`${max}\`` : "least `1`";
            const subText = SUB_TEXT_BY_ERROR_TYPE[type] || "";
            errMsgItem += t(`error.${errCode}-content`, {
              field_name: fields,
              file_name: getFileName(fileName),
              detail_msg: descMsg,
              limit_value: limitVal,
              sub_text: subText,
            });
          }
          break;
      }
      outputList.push(errMsgItem);
    }

    return outputList;
  };

  const onChangePage = (value) => {
    setParams({
      ...params,
      page: value,
    });
    handleScrollToTop();
  };

  const checkDashboardUrl = async (simulationData, params, retryCount) => {
    try {
      const res = await getDashboardLink(params);
      if (res.data.status === DASHBOARD_STATUS.INPROGRESS) {
        if (retryCount === RETRY_TIME) {
          showAlert({
            status: true,
            message: t("over_time"),
            type: "danger",
          });
          setDashboardLoading((oldVal) => ({ ...oldVal, [params.id]: false }));
          return;
        }
        await new Promise((resolve) => setTimeout(resolve, 3000));
        retryCount += 1;
        await checkDashboardUrl(simulationData, params, retryCount);
        return;
      }
      await refetch();
      setDashboardLoading((oldVal) => ({ ...oldVal, [params.id]: false }));
      if (res.data.dashboard_status === DASHBOARD_STATUS.SUCCEEDED) {
        window.open(res.data.dashboard_url, "_blank");
        return;
      }
    } catch (e) {
      setDashboardLoading((oldVal) => ({ ...oldVal, [params.id]: false }));
      showAlert({
        status: true,
        message: e?.response?.data?.error?.message || e?.response?.data?.message || t("common:network_error"),
        type: "danger",
      });
    }
  };

  const handleClickDashboard = async (row) => {
    const simulationData = row?.simulator_dashboard;
    if (simulationData?.dashboard_status === DASHBOARD_STATUS.SUCCEEDED && simulationData?.dashboard_url) {
      window.open(simulationData?.dashboard_url, "_blank");
      return;
    }
    setDashboardLoading({ ...dashboardLoading, [row.id]: true });
    const data = {
      id: row.id,
      dashboard_input_type: DASHBOARD_INPUT_TYPE.MULTIPLE,
      dashboard_type: DASHBOARD_TYPE.GNC_MULTIPLE,
      input_file_id: null,
    };
    await checkDashboardUrl(simulationData, data, 1);
  };

  const onKeyDownInput = (e) => {
    if (e.key === "Enter") {
      onSubmitSearch(e.target.value);
    }
  };

  const onSubmitSearch = (textSearch) => {
    if (params?.condition === textSearch && !isLoadingSimulator) {
      setIsLoadingSimulator(true);
      refetch();
    }
    setParams({
      page: 1,
      condition: textSearch,
    });
  };

  const getCurrentPage = () => {
    return (params?.page || PAGINATION_DEFAULT.page) - 1;
  };

  useEffect(() => {
    setIsLoadingSimulator(true);
    refetch();
    setTextSearch(params?.condition);
  }, [params]);

  useEffect(() => {
    if (resetForm) {
      setTextSearch(null);
      setResetForm(false);
    }
  }, [resetForm]);

  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="header-title mb-0">{t("list.title")}</h2>
                <SearchInput
                  submit={onSubmitSearch}
                  setTextSearch={setTextSearch}
                  onKeyDownInput={onKeyDownInput}
                  textSearch={textSearch}
                  placeholder={t("list.search_placeholder")}
                />
              </div>
              <Row>
                <Col md={12}>
                  <Card>
                    <div className="d-flex justify-content-end">
                      <CardHeader className="bg-white border-0">
                        <Link to={APP_ROUTES.GNC_SIMULATOR_NEW} className="btn btn-primary text-capitalize">
                          {t("list.redirect_new")}
                        </Link>
                      </CardHeader>
                    </div>
                    <CardBody className="bg-white">
                      <TableCommon
                        loading={isLoadingSimulator}
                        columns={columns}
                        data={dataSimulator?.items || []}
                        onChangePage={onChangePage}
                        tableFixed
                        tableClassName="table striped table-collapse table-fixed"
                        customClassName="overflow-auto simulation-table"
                        pageCurrent={getCurrentPage()}
                        pageCount={dataSimulator?.pagination?.total_page}
                        totalItem={dataSimulator?.pagination?.total}
                        pageSize={PAGINATION_DEFAULT.pageSize}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
      <ModalSeriesFilesView {...dataModalFileView} onClose={() => setDataModalFileView((oldVal) => ({ ...oldVal, isOpen: false }))} />
      {caseResult && <ModalCaseResult closeModal={() => setCaseResult(null)} isOpen={!!caseResult} id={caseResult} />}{" "}
    </Container>
  );
};

export default GNCSimulator;
