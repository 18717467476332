const CREATE_SIMULATOR = "v1/simulators";
const EDIT_SIMULATOR = (id) => `v1/simulators/${id}`;
const GET_SIMULATOR = "v1/simulators";
const SIMULATOR_DETAIL = (id) => `v1/simulators/${id}`;
const PATCH_SIMULATION = (id) => `/v1/simulators/${id}`;
const GET_SIMULATOR_OPTIONS = (type) => `v1/versions?type=${type}`;
const POST_PRESIGNED_URL = "v1/simulator-pre-signed-upload";
const GET_SERIES_INPUT = (id) => `v1/simulators/${id}/series-input`;
const GET_SERIES_DASHBOARD = (id) => `/v1/simulators/${id}/dashboards/main`;
const GET_6DOF_DASHBOARD = (id) => `/v1/simulators/${id}/simulator-dashboards`;
const GET_SENSITIVITY_DASHBOARD = (id) => `/v1/simulators/${id}/dashboards/sensitivity-analysis`;
const GET_MULTIPLE_OPT_DASHBOARD = (id) => `/v1/simulators/${id}/dashboards/multiple-optimization`;
const GET_MULTIPLE_DASHBOARD = (id) => `/v1/simulators/${id}/dashboards/main-multiple`;
const GET_URL_SIGN_IN = `v1/login`;
const GET_ME = `v1/user/me`;
const GET_TEST_ANALYSIS = "v1/test-analyses";
const GET_TEST_ANALYSIS_PROCESS = "v1/test-analysis-processes";
const CREATE_TEST_ANALYSIS = "v1/test-analyses";
const DELETE_TEST_ANALYSIS_ITEM = (id) => `v1/test-analysis-items/${id}`;
const GET_ROCKET_MODEL = "v1/rocket-models";
const CREATE_ROCKET_MODEL = "v1/rocket-models";
const BULK_THRUST_CALCULATOR_TO_ROCKET_MODEL = (id) => `v1/rocket-models/${id}/thrust-calculations`;
const ROCKET_MODEL_DETAIL = (id) => `v1/rocket-models/${id}`;
const EDIT_ROCKET_MODEL = (id) => `v1/rocket-models/${id}`;
const GET_ROCKET_MODEL_DETAIL = (id) => `v1/rocket-models/${id}`;
const DELETE_ROCKET_MODEL = (id) => `v1/rocket-models/${id}`;
const GET_ROCKET_MODEL_HISTORIES = (id) => `v1/rocket-models/${id}/histories`;
const GET_ROCKET_MODEL_THRUST_CALCULATOR = (id) => `v1/rocket-models/${id}/thrust-calculations`;
const GET_THRUST_CALCULATOR = "v1/thrust-calculations";
const GET_THRUST_CALCULATOR_DETAIL = (id) => `v1/thrust-calculations/${id}`;
const CREATE_THRUST_CALCULATOR = "v1/thrust-calculations";
const GET_ENGINE_COMPONENT = "v1/engine-components";
const CREATE_ENGINE_COMPONENT = "v1/engine-components";
const EDIT_ENGINE_COMPONENT = (id) => `v1/engine-components/${id}`;
const GET_ENGINE_COMPONENT_DETAIL = (id) => `v1/engine-components/${id}`;
const POST_PRE_SIGNED_UPLOAD = `v1/pre-signed-upload`;
const POST_TEST_ANALYSIS_ITEM = `v1/test-analysis-items`;

//GNC SIMULATOR
const CREATE_GNC_SIMULATOR = "v1/gnc-simulators";
const EDIT_GNC_SIMULATOR = (id) => `v1/gnc-simulators/${id}`;
const GET_GNC_SIMULATOR = "v1/gnc-simulators";
const GNC_SIMULATOR_DETAIL = (id) => `v1/gnc-simulators/${id}`;
const GET_GNC_SIMULATOR_OPTIONS = (type) => `v1/versions?type=${type}`;
const GET_GNC_SERIES_INPUT = (id) => `v1/gnc-simulators/${id}/series-input`;
const GET_GNC_SERIES_DASHBOARD = (id) => `v1/simulators/${id}/simulator-dashboards`;
const GET_GNC_CASES_RESULT = (id) => `/v1/gnc-simulators/${id}/landing-results`;

//NAL WIND TUNNEL
const GET_NAL_TEST_ANALYSIS = "v1/nal-test-analyses";
const GET_NAL_TEST_ANALYSIS_PROCESS = "v1/nal-test-analysis-processes";
const CREATE_NAL_TEST_ANALYSIS = "v1/nal-test-analyses";
const DELETE_NAL_TEST_ANALYSIS_ITEM = (id) => `v1/nal-test-analysis-items/${id}`;
const POST_NAL_TEST_ANALYSIS_ITEM = `v1/nal-test-analysis-items`;

export {
  CREATE_SIMULATOR,
  EDIT_SIMULATOR,
  POST_PRESIGNED_URL,
  GET_SIMULATOR_OPTIONS,
  SIMULATOR_DETAIL,
  GET_SIMULATOR,
  GET_SERIES_INPUT,
  GET_URL_SIGN_IN,
  GET_ME,
  GET_SERIES_DASHBOARD,
  GET_SENSITIVITY_DASHBOARD,
  GET_MULTIPLE_OPT_DASHBOARD,
  GET_MULTIPLE_DASHBOARD,
  GET_TEST_ANALYSIS,
  GET_TEST_ANALYSIS_PROCESS,
  CREATE_TEST_ANALYSIS,
  DELETE_TEST_ANALYSIS_ITEM,
  POST_TEST_ANALYSIS_ITEM,
  GET_ROCKET_MODEL,
  CREATE_ROCKET_MODEL,
  ROCKET_MODEL_DETAIL,
  EDIT_ROCKET_MODEL,
  GET_ROCKET_MODEL_DETAIL,
  GET_ENGINE_COMPONENT_DETAIL,
  DELETE_ROCKET_MODEL,
  GET_THRUST_CALCULATOR,
  GET_THRUST_CALCULATOR_DETAIL,
  CREATE_THRUST_CALCULATOR,
  GET_ENGINE_COMPONENT,
  CREATE_ENGINE_COMPONENT,
  EDIT_ENGINE_COMPONENT,
  POST_PRE_SIGNED_UPLOAD,
  GET_6DOF_DASHBOARD,
  GET_ROCKET_MODEL_HISTORIES,
  GET_ROCKET_MODEL_THRUST_CALCULATOR,
  BULK_THRUST_CALCULATOR_TO_ROCKET_MODEL,
  CREATE_GNC_SIMULATOR,
  EDIT_GNC_SIMULATOR,
  GET_GNC_SIMULATOR,
  GNC_SIMULATOR_DETAIL,
  GET_GNC_SIMULATOR_OPTIONS,
  GET_GNC_SERIES_INPUT,
  GET_GNC_SERIES_DASHBOARD,
  GET_GNC_CASES_RESULT,
  GET_NAL_TEST_ANALYSIS,
  GET_NAL_TEST_ANALYSIS_PROCESS,
  CREATE_NAL_TEST_ANALYSIS,
  DELETE_NAL_TEST_ANALYSIS_ITEM,
  POST_NAL_TEST_ANALYSIS_ITEM,
  PATCH_SIMULATION,
};
