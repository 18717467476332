import { atom } from "recoil";
import { alertDefault } from "src/constant/common";

export const alertState = atom({
  key: "alertState",
  default: alertDefault,
});

export const navigationPromptState = atom({
  key: "navigationPromptState",
  default: false,
});

export const resetFormPromptState = atom({
  key: "resetFormPromptState",
  default: false,
});
