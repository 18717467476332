import React, { useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import { MAX_HEIGHT_DISPLAY } from "src/constant/common";

const ShowDataCell = React.memo(({ data, customClass }) => {
  const ref = useRef();
  const [showMore, setShowMore] = useState(false);
  const [isShowMore, setIsShowMore] = useState(true);

  useLayoutEffect(() => {
    setShowMore(false);
    setIsShowMore(true);
    setTimeout(() => {
      if (ref?.current?.clientHeight > MAX_HEIGHT_DISPLAY) {
        setShowMore(true);
        setIsShowMore(false);
      }
    }, 0);
  }, [data]);

  return data ? (
    <>
      <div>
        <p
          className={classNames("mb-0 text-break-space", {
            "line-clamp-3 text-ellipsis height-by-px-60": !isShowMore,
          })}
          ref={ref}
        >
          {data}
        </p>
      </div>
      {showMore && (
        <span
          role="button"
          className={classNames("btn btn-link p-0 border-0 inline-block", customClass)}
          onClick={() => {
            setIsShowMore(!isShowMore);
          }}
        >
          {isShowMore ? " Show less" : " Show more"}
        </span>
      )}
    </>
  ) : null;
});

export default ShowDataCell;
