import { useMutation, useQuery } from "src/Hooks/useReactQuery";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import routeGuard from "src/HOC/routeGuard";
import FormGNCSimulator, { FORM_NAME } from "src/components/GNCSimulator/form";
import APP_ROUTES from "src/routes/routes";
import { createSimulator, editSimulator, getSimulatorOptions, getSimulatorsDetail } from "src/services/simulator-gnc";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import { useTranslation } from "react-i18next";
import { FORM_TYPE, PROCESS_STATUS, SIMULATION_TYPE } from "src/constant/common";
import { transformDataVersionsToOptions } from "src/utils/common";
import Loading from "src/components/Common/Loading";

const GNCSimulatorDetail = () => {
  const { t } = useTranslation("gnc-simulator");
  const navigate = useNavigate();
  const showAlert = useSetRecoilState(alertState);
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const formAction = searchParams.get("action");
  const page = searchParams.get("page");
  const condition = searchParams.get("condition");

  const [isLoadingDetail, setIsLoadingDetail] = useState(true);
  const [isLoadingVersion, setIsLoadingVersion] = useState(true);

  const getRedirectURL = useCallback(
    () => `${APP_ROUTES.GNC_SIMULATOR}?id=${id}${page ? "&page=" + page : ""}${condition ? "&condition=" + condition : ""}`,
    [id, page, condition]
  );

  const { data, mutate: getSimulatorDetailMutate } = useMutation({
    mutationFn: () => getSimulatorsDetail(id),
    onSettled: () => setIsLoadingDetail(false),
    onSuccess: (data) => {
      if (data?.status === PROCESS_STATUS.SUCCEEDED && formAction !== FORM_TYPE.COPY) {
        return navigate(APP_ROUTES.NOT_FOUND);
      }
      if (data?.status === PROCESS_STATUS.FAILED && formAction !== FORM_TYPE.EDIT) {
        return navigate(APP_ROUTES.NOT_FOUND);
      }
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        return navigate(APP_ROUTES.NOT_FOUND);
      }
    },
  });

  const { data: versions } = useQuery(["getGNCVersions"], () => getSimulatorOptions(SIMULATION_TYPE["2D3F"]), {
    refetchOnWindowFocus: false,
    onSettled: () => setIsLoadingVersion(false),
  });

  const createSimulatorMutate = useMutation({
    mutationFn: ({ data }) => createSimulator({ data }),
  });

  const editSimulatorMutate = useMutation({
    mutationFn: ({ data }) => editSimulator({ data }),
  });

  const submitData = ({ data }, callback) => {
    if (formAction === FORM_TYPE.EDIT) {
      data.id = id;
      return editSimulatorMutate.mutate(
        { data },
        {
          onSuccess: () => {
            showAlert({
              status: true,
              message: t("new.retry_success"),
              type: "success",
            });
            navigate(`${APP_ROUTES.GNC_SIMULATOR}?id=${id}${page ? "&page=" + page : ""}${condition ? "&condition=" + condition : ""}`);
          },
          onSettled: () => callback(),
        }
      );
    }

    createSimulatorMutate.mutate(
      { data },
      {
        onSuccess: () => {
          showAlert({
            status: true,
            message: t("new.create_success"),
            type: "success",
          });

          navigate(APP_ROUTES.GNC_SIMULATOR);
        },
        onSettled: () => callback(),
      }
    );
  };

  useEffect(() => {
    getSimulatorDetailMutate();
    window.onpopstate = () => {
      navigate(getRedirectURL());
    };
  }, []);

  const transformDetailData = (data) => {
    data[FORM_NAME.PARAMS] = data?.series?.params || [];
    return {
      ...data,
      name: formAction === FORM_TYPE.EDIT ? data?.name : `Copy of ${data?.name}`,
    };
  };

  if (isLoadingVersion || isLoadingDetail) return <Loading />;

  return (
    <>
      <FormGNCSimulator
        submitData={submitData}
        data={transformDetailData(data)}
        title={t("new.title_detail")}
        getRedirectURL={getRedirectURL}
        versions={transformDataVersionsToOptions(versions || [])}
      />
    </>
  );
};

export default routeGuard(GNCSimulatorDetail);
