import axios from "axios";
import api from "src/configs/api";
import {
  CREATE_SIMULATOR,
  EDIT_SIMULATOR,
  GET_SIMULATOR,
  GET_SERIES_INPUT,
  GET_SIMULATOR_OPTIONS,
  SIMULATOR_DETAIL,
  GET_SERIES_DASHBOARD,
  GET_6DOF_DASHBOARD,
  GET_SENSITIVITY_DASHBOARD,
  GET_MULTIPLE_OPT_DASHBOARD,
  GET_MULTIPLE_DASHBOARD,
  PATCH_SIMULATION,
} from "src/constant/endpoint";

const getSimulators = async (params) => await api.get(GET_SIMULATOR, { params }).then((response) => response?.data?.data);

const getSimulatorsDetail = async (id) => await api.get(SIMULATOR_DETAIL(id)).then((response) => response?.data?.data);

const createSimulator = ({ data }) => api.post(CREATE_SIMULATOR, { data }).then((response) => response?.data);

const editSimulator = ({ data }) => api.put(EDIT_SIMULATOR(data.id), { data }).then((response) => response?.data);

const patchSimulator = async ({ id, data }) =>
  await api.patch(PATCH_SIMULATION(id), { data }).then((response) => response?.data);

const getSimulatorOptions = async (type) => await api.get(GET_SIMULATOR_OPTIONS(type)).then((response) => response?.data?.data);

const getSeriesInput = async (id) => await api.get(GET_SERIES_INPUT(id)).then((response) => response?.data?.data);

const getContentFromLink = async (link) => axios.get(link);

const getDashboardLink = async (data) => await api.post(GET_SERIES_DASHBOARD(data.id), { data: data.params });

const getSensitivityDashboardLink = async (data) => await api.post(GET_SENSITIVITY_DASHBOARD(data.id), { data: data.params });

const getMultipleOptDashboardLink = async (data) => await api.post(GET_MULTIPLE_OPT_DASHBOARD(data.id), { data });

const getMultipleDashboardLink = async (data) => await api.post(GET_MULTIPLE_DASHBOARD(data.id), { data });

const get6DofDashboardLink = async (data) => {
  const { id, ...params } = data;
  return await api.post(GET_6DOF_DASHBOARD(id), { data: params });
};

export {
  createSimulator,
  editSimulator,
  getSimulatorOptions,
  getSimulators,
  getSimulatorsDetail,
  getSeriesInput,
  getContentFromLink,
  getDashboardLink,
  getSensitivityDashboardLink,
  getMultipleOptDashboardLink,
  getMultipleDashboardLink,
  get6DofDashboardLink,
  patchSimulator,
};
