import React, { useEffect, useRef } from "react";
import { useHover } from "ahooks";

export const SearchInput = ({ setTextSearch, onKeyDownInput, textSearch, submit, placeholder = "" }) => {
  const searchRef = useRef();
  const searchBlockRef = useRef();
  const isHovering = useHover(searchBlockRef);

  useEffect(() => {
    searchRef.current.value = textSearch ?? "";
  }, [textSearch]);

  return (
    <div className="input-group search-input" ref={searchBlockRef}>
      <input
        className="form-control p-r-30"
        placeholder={placeholder}
        name="search_content"
        onKeyDown={onKeyDownInput}
        onChange={(e) => {
          setTextSearch(e.target.value.trim());
        }}
        defaultValue={textSearch}
        ref={searchRef}
      />

      {searchRef?.current?.value && isHovering && (
        <div
          role="button"
          className="position-absolute translate-y-middle  remove-search-text z-i-5"
          onClick={() => {
            setTextSearch("");
            searchRef.current.value = "";
            submit("");
          }}
        >
          <i className="bi bi-x f-s-20" />
        </div>
      )}

      <button
        className="btn input-group-text z-i-5 border"
        onClick={() => {
          submit(textSearch);
        }}
      >
        <i className="bi bi-search f-s-20 text-dark" />
      </button>
    </div>
  );
};

export default SearchInput;
