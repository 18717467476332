export const PAGINATION_DEFAULT = {
  page: 1,
  pageSize: 50,
  smallPageSize: 10,
};

export const PROCESS_STATUS = {
  SUBMITTED: "SUBMITTED",
  PENDING: "PENDING",
  RUNNABLE: "RUNNABLE",
  STARTING: "STARTING",
  RUNNING: "RUNNING",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  IN_PROGRESS: "IN_PROGRESS",
  TERMINATED: "TERMINATED",
  TERMINATING: "TERMINATING",
};

export const PROCESS_STATUS_NAME = {
  SUBMITTED: "SUBMITTED",
  PENDING: "PENDING",
  RUNNABLE: "RUNNABLE",
  STARTING: "STARTING",
  RUNNING: "RUNNING",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  IN_PROGRESS: "IN PROGRESS",
  TERMINATED: "TERMINATED",
  TERMINATING: "TERMINATING",
};

export const ALLOW_EDIT_STATUS = [PROCESS_STATUS.SUCCEEDED, PROCESS_STATUS.FAILED, PROCESS_STATUS.PENDING];

export const alertDefault = {
  status: false,
  message: "",
  type: "success",
};

export const UPLOAD_FAIL_TYPE = {
  LIMIT: "limit",
};

export const TOAST_DISPLAY_TIME = 15000;
export const REFETCH_INTERVAL_TIME = 15000;
export const RETRY_TIME = 10;

export const FILE_TYPE = {
  JSON: "json",
  CSV: "csv",
};

export const GET_LABEL_WITH_STATUS = {
  [PROCESS_STATUS.SUBMITTED]: "primary",
  [PROCESS_STATUS.RUNNING]: "info",
  [PROCESS_STATUS.SUCCEEDED]: "success",
  [PROCESS_STATUS.SUCCESSFUL]: "success",
  [PROCESS_STATUS.FAILED]: "danger",
  [PROCESS_STATUS.IN_PROGRESS]: "info",
  [PROCESS_STATUS.TERMINATING]: "secondary",
  [PROCESS_STATUS.TERMINATED]: "dark",
};

export const LOADING_STATUS = {
  LOADING: "LOADING",
  AUTHENTICATED: "authenticated",
  UNAUTHENTICATED: "unauthenticated",
};

export const SORT_METHODS = {
  ASC: "ASC",
  DESC: "DESC",
};

export const FORM_TYPE = {
  EDIT: "retry",
  NEW: "new",
  COPY: "copy",
};

export const SIMULATION_RESULT = {
  SINGLE: "main.m",
  MULTI: "multiOptimization.m",
  PAYLOAD: "payloadOptimization.m",
  SIX_DOF: "simulateFlightTrajectory6DoF.m",
};

export const UPLOAD_TYPE = {
  RAW: "raw_data",
  COEFFICIENT: "aerodynamic_coefficients_data",
  "6DOF": "simulation_6dof",
  GNC: "simulation_2d3f",
};

export const DASHBOARD_STATUS = {
  SUCCEEDED: "successful",
  INPROGRESS: "in_progress",
  FAILED: "failed",
  FIXED: "fixed",
};

export const STATUS_ERROR_CODE = {
  NOT_AUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const PATTERN_NUMBER = /^\s*-?\d+(\.\d*)?([eE][-+]?\d+)?\s*$/;

export const PATTERN_NEGATIVE_NUMBER = /^\s*-?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?\s*$/;
export const PATTERN_INTEGER_NEGATIVE_NUMBER = /^\s*(-?\d+)\s*$/;

export const PATTERN_GRAVITY_OF_CENTER = /^\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*$/;

export const SIMULATION_TYPE = {
  "3DOF": "3DoF",
  "6DOF": "6DoF",
  "2D3F": "2D3F",
};

export const EXECUTION_FILE_TYPE = {
  MAIN: "calculateMassShape.m",
  MAIN_6DOF: "simulateFlightTrajectory6DoF.m",
};

export const ERROR_CODE_BY_TYPE = {
  json_syntax_error: "FE-0001",
  model_type: "FE-0002",
  missing: "FE-0004",
  "type_error.float": "FE-0005",
  "type_error.int": "FE-0005",
  "type_error.string": "FE-0005",
  string_type: "FE-0005",
  int_parsing: "FE-0005",
  float_parsing: "FE-0005",
  float_type: "FE-0005",
  int_type: "FE-0005",
  "type_error.list": "FE-0005",
  list_type: "FE-0005",
  literal_error: "FE-0006",
  required_ranges: "FE-0006",
  check_positive: "FE-0006",
  check_non_negative: "FE-0006",
  match_length: "FE-0006",
  match: "FE-0007",
  FE_0007: "FE-0007",
  too_short: "FE-0008",
  string_too_short: "FE-0004",
  too_long: "FE-0008",
  empty_data: "FE-0010",
  csv_syntax_error: "FE-0010",
  redundant_column: "FE-0011",
  missing_column: "FE-0011",
  order_mixup: "FE-0011",
  data_empty: "FE-0010",
  SJ_0001: "SJ-0001",
  SJ_0002: "SJ-0002",
  SJ_0003: "FE-0006",
  SJ_0004: "FE-0006",
  SE_0500: "SE-0500",
  SE_0501: "SE-0501",
};

export const SUB_TEXT_BY_ERROR_TYPE = {
  redundant_column: "redundant column",
  missing_column: "missing column",
  order_mixup: "incorrect column",
};

export const DASHBOARD_TYPE = {
  MAIN: "main",
  SENSITIVITY: "sensitivity",
  ASCENT_6DOF: "dashboard_type_6dof_ascent",
  DESCENT_6DOF: "dashboard_type_6dof_descent",
  GNC_MULTIPLE: "dashboard_type_2d3f",
  GNC_SINGLE: "dashboard_type_2d3f",
  DISPERSION_ERROR: "dashboard_type_3dof_error_metrics",
  DISPERSION_RSS: "dashboard_type_3dof_rss_metrics",
};
export const DASHBOARD_STATUS_BY_TYPE = {
  [DASHBOARD_TYPE.MAIN]: "dashboard_status",
  [DASHBOARD_TYPE.SENSITIVITY]: "sensitivity_analysis_dashboard_status",
  [DASHBOARD_TYPE.DISPERSION_ERROR]: "dashboard_status",
  [DASHBOARD_TYPE.DISPERSION_RSS]: "dashboard_status",
};
export const DASHBOARD_URL_BY_TYPE = {
  [DASHBOARD_TYPE.MAIN]: "dashboard_url",
  [DASHBOARD_TYPE.SENSITIVITY]: "sensitivity_analysis_dashboard_url",
  [DASHBOARD_TYPE.DISPERSION_ERROR]: "dashboard_url",
  [DASHBOARD_TYPE.DISPERSION_RSS]: "dashboard_url",
};
export const DASHBOARD_INPUT_TYPE = {
  MULTIPLE: "multiple",
  SINGLE: "single",
};

export const REFRESH_TIME = 15;

export const MAX_HEIGHT_DISPLAY = 65;

export const HISTORIES_ACTION_NAME = {
  CREATE_ROCKET_MODEL: "create_rocket_model",
  DELETE_ROCKET_MODEL: "delete_rocket_model",
  UPDATE_ROCKET_MODEL: "update_rocket_model",
  ADD_NEW_CALCULATION: "add_new_calculation",
  UPDATE_ENGINE_COMPONENT: "update_engine_component",
  UPDATE_COMPONENT_DETAIL: "update_component_detail",
  RUN_SIMULATION: "run_simulation",
  CALCULATE_THRUST: "calculate_thrust",
  SAVE_UNPROCESSED_COMPONENT_DETAILS: "save_unprocessed_component_details",
};

export const MAPPING_HISTORIES_ACTION_TO_TEXT = (t) => ({
  [HISTORIES_ACTION_NAME.CREATE_ROCKET_MODEL]: t("logs.created"),
  [HISTORIES_ACTION_NAME.UPDATE_ROCKET_MODEL]: t("logs.updated"),
  [HISTORIES_ACTION_NAME.ADD_NEW_CALCULATION]: t("logs.updated"),
  [HISTORIES_ACTION_NAME.UPDATE_ENGINE_COMPONENT]: t("logs.updated"),
  [HISTORIES_ACTION_NAME.UPDATE_COMPONENT_DETAIL]: t("logs.updated"),
  [HISTORIES_ACTION_NAME.RUN_SIMULATION]: t("logs.updated"),
  [HISTORIES_ACTION_NAME.CALCULATE_THRUST]: t("logs.updated"),
  [HISTORIES_ACTION_NAME.SAVE_UNPROCESSED_COMPONENT_DETAILS]: t("logs.updated"),
  [HISTORIES_ACTION_NAME.DELETE_ROCKET_MODEL]: t("logs.deleted"),
});

export const INIT_ROCKET_COMPONENT_DETAIL_COLUMNS = {
  mass: 0,
  l_axis_position: 0,
  length: 0,
  center_of_gravity_x: 0,
  center_of_gravity_y: 0,
  center_of_gravity_z: 0,
  outer_diameter: 0,
  inner_diameter: 0,
  moment_of_inertia_x: 0,
  moment_of_inertia_y: 0,
  moment_of_inertia_z: 0,
  product_of_inertia_x: 0,
  product_of_inertia_y: 0,
  product_of_inertia_z: 0,
};

export const ROCKET_COMPONENT_LEVEL0 = [
  { value: "Fairing", label: "Fairing" },
  { value: "Payload", label: "Payload" },
  { value: "1st Stage Inert", label: "1st Stage Inert" },
  { value: "1st Stage Propellant", label: "1st Stage Propellant" },
  { value: "2nd Stage Inert", label: "2nd Stage Inert" },
  { value: "2nd Stage Propellant", label: "2nd Stage Propellant" },
  { value: "3rd Stage Inert", label: "3rd Stage Inert" },
  { value: "3rd Stage Propellant", label: "3rd Stage Propellant" },
];

export const STAGE = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

export const ROCKET_COMPONENTS = {
  [STAGE.ONE]: {
    [STAGE.ONE]: [
      { value: "Fairing", label: "Fairing" },
      { value: "Payload", label: "Payload" },
      { value: "1st Stage Inert", label: "1st Stage Inert" },
      { value: "1st Stage Propellant", label: "1st Stage Propellant" },
    ],
  },
  [STAGE.TWO]: {
    [STAGE.ONE]: [
      { value: "1st Stage Inert", label: "1st Stage Inert" },
      { value: "1st Stage Propellant", label: "1st Stage Propellant" },
    ],
    [STAGE.TWO]: [
      { value: "Fairing", label: "Fairing" },
      { value: "Payload", label: "Payload" },
      { value: "2nd Stage Inert", label: "2nd Stage Inert" },
      { value: "2nd Stage Propellant", label: "2nd Stage Propellant" },
    ],
  },
  [STAGE.THREE]: {
    [STAGE.ONE]: [
      { value: "1st Stage Inert", label: "1st Stage Inert" },
      { value: "1st Stage Propellant", label: "1st Stage Propellant" },
    ],
    [STAGE.TWO]: [
      { value: "2nd Stage Inert", label: "2nd Stage Inert" },
      { value: "2nd Stage Propellant", label: "2nd Stage Propellant" },
    ],
    [STAGE.THREE]: [
      { value: "Fairing", label: "Fairing" },
      { value: "Payload", label: "Payload" },
      { value: "3rd Stage Inert", label: "3rd Stage Inert" },
      { value: "3rd Stage Propellant", label: "3rd Stage Propellant" },
    ],
  },
};

export const getOrdinalNumber = (count) => {
  if (count === 1) return "st";
  if (count === 2) return "nd";
  if (count === 3) return "rd";

  return "th";
};

export const INIT_ROCKET_COMPONENT_DETAIL_ITEM = (stageCount) =>
  Array.from({ length: stageCount }, (_, i) => ({
    name: `${stageCount - i}`,
    type: `stage`,
    children: [
      {
        name: `${stageCount - i}${getOrdinalNumber(stageCount - i)} Stage Inert`,
        type: "level",
        original_stage: stageCount - i,
        children: [
          {
            name: `Level ${stageCount - i} name`,
            type: "level",
            children: [
              {
                name: "Component name",
                type: "component",
                properties: {
                  ...PROPERTIES_TEMPLATE,
                },
              },
            ],
          },
        ],
      },
    ],
  }));

export const PROPERTIES_TEMPLATE = {
  mass: {
    unit: "kg",
    value: 0,
  },
  length: {
    unit: "m",
    value: 0,
  },
  inner_diameter: {
    unit: "m",
    value: 0,
  },
  outer_diameter: {
    unit: "m",
    value: 0,
  },
  l_axis_position: 0,
  center_of_gravity: {
    unit: "m",
    value: [0, 0, 0],
  },
  moment_of_inertia: {
    unit: "kgm^2",
    value: [0, 0, 0],
  },
  product_of_inertia: {
    unit: "kgm^2",
    value: [0, 0, 0],
  },
};

export const MODEL_DETAIL_TEMPLATE_CSV = {
  1: `,mass (kg),length (m),center_of_gravity_gx (m),center_of_gravity_gy (m),center_of_gravity_gz (m),diameter (m),tensor_of_inertia (kgm^2)
gross,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
dry,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
dry_without_fairing,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage1,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage1_without_fairing,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage1_without_fairing_payload,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"`,
  2: `,mass (kg),length (m),center_of_gravity_gx (m),center_of_gravity_gy (m),center_of_gravity_gz (m),diameter (m),tensor_of_inertia (kgm^2)
gross,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
dry,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
dry_without_fairing,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage1,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage2,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage2_without_fairing,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage2_without_fairing_payload,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"`,
  3: `,mass (kg),length (m),center_of_gravity_gx (m),center_of_gravity_gy (m),center_of_gravity_gz (m),diameter (m),tensor_of_inertia (kgm^2)
gross,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
dry,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
dry_without_fairing,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage1,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage2,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage3,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage3_without_fairing,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"
stage3_without_fairing_payload,0.0,0.0,0.0,0.0,0.0,0.0,"[[0.0,0.0,0.0][0.0,0.0,0.0][0.0,0.0,0.0]]"`,
};

export const STAGE_OPTIONS = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
];

export const STAGE_MODES_OPTIONS = [
  { label: "Ascent", value: "ascent" },
  { label: "Descent", value: "descent" },
];

export const ACTION_NAME = {
  RUN_SIMULATION: "run_batch_calculation",
};

export const PROGRESS_STATUS = {
  FIXED: "fixed",
  IN_PROGRESS: "in_progress",
};

export const CHART_CONTAINER_WIDTH = {
  SM: 300,
  MD: 600,
};

export const CHART_LIMIT_TICKS = {
  SMALL: 5,
  MEDIUM: 10,
  BIG: 10,
};

export const SIMULATION_TYPE_OPTIONS = {
  "3DOF": SIMULATION_TYPE["3DOF"],
  "6DOF": SIMULATION_TYPE["6DOF"],
};

export const CONFIG_FIELD_ORDER = [
  "representative_surface_areas",
  "representative_length",
  "mach_classification_unit",
  "test_airflow_settling",
  "base_area",
  "cavity_area",
  "angle_of_attack_classification_unit",
  "min_angle_of_attack",
  "max_angle_of_attack",
  "error_range_of_angle_of_attack",
];

export const VALIDATE_RULE_BY_FIELD = (t) => ({
  min_angle_of_attack: {
    max: {
      field: "max_angle_of_attack",
      msg: t("new.aoa_min_validate"),
    },
    customClass: "col-4 d-inline-block pl-1 m-b-35",
    allowNegative: true,
    unit: "deg",
  },
  max_angle_of_attack: {
    min: {
      field: "min_angle_of_attack",
      msg: t("new.aoa_max_validate"),
    },
    customClass: "col-4 d-inline-block px-1 m-b-35",
    allowNegative: true,
    notShowLabel: true,
  },
  error_range_of_angle_of_attack: {
    customClass: "col-4 d-inline-block pr-1 m-b-35",
    notShowLabel: true,
  },
  test_airflow_settling: {
    allowNegative: true,
    unit: "s",
  },
  angle_of_attack_classification_unit: {
    unit: "deg",
  },
  representative_surface_areas: {
    unit: "m\u00B2",
  },
  representative_length: {
    unit: "m",
  },
  base_area: {
    unit: "m\u00B2",
  },
  cavity_area: {
    unit: "m\u00B2",
  },
});
