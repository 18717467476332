import React from "react";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ButtonFileViewer, ButtonErrorWarning } from "src/components/Common/Button";

const SeriesData = ({ isLoading, isError, serieData, handleViewFile, simulationData }) => {
  const { t } = useTranslation(["gnc-simulator", "common"]);

  return isError ? (
    <div className="p-2 d-flex align-items-center justify-content-center w-100 height-by-px-70">{t("common:network_error")}</div>
  ) : isLoading ? (
    <div className="p-2 d-flex align-items-center justify-content-center w-100">
      <Spinner color="secondary" />
    </div>
  ) : (
    <div className="react-bootstrap-table table-detail overflow-auto">
      <table className="table">
        <thead className="table-secondary">
          <tr>
            <th className="width-by-px-60"></th>
            <th className="width-by-px-60"></th>
            {serieData?.columns?.map((col, indexCol) => (
              <th key={indexCol}>{col.text}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {serieData?.data?.map((serieRow, indexSerie) => (
            <tr key={indexSerie}>
              <td>
                <div className="d-flex gap-2 align-items-center min-height-by-px-30 min-width-by-px-30">
                  <ButtonFileViewer
                    handleClick={() => handleViewFile({ isOpen: true, serieData, fileData: serieRow })}
                    id={`${simulationData.id}-${indexSerie}`}
                  />
                </div>
              </td>
              <td>
                <div className="d-flex gap-2 align-items-center min-height-by-px-30 min-width-by-px-30">
                  <ButtonErrorWarning
                    isShow={serieRow?.errorList?.length > 0}
                    errorContent={serieRow.errorList.join("\n")}
                    id={`${simulationData.id}-${indexSerie}`}
                  />
                </div>
              </td>

              {serieData?.columns?.map((col, indexCol) => (
                <td key={indexCol}>{serieRow.paramList[col.dataField]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SeriesData;
