import React from "react";
import routeGuard from "src/HOC/routeGuard";
import WindTunnelTestNalList from "src/components/TestAnalysis/WindTunnel";

const WindTunnelTestNal = () => {
  return (
    <WindTunnelTestNalList />
  );
};

export default routeGuard(WindTunnelTestNal);
