import { useMutation, useQuery } from "src/Hooks/useReactQuery";
import React from "react";
import { useNavigate } from "react-router-dom";
import routeGuard from "src/HOC/routeGuard";
import FormGNCSimulator, { DEFAULT_DATA_PARAMS, FORM_NAME } from "src/components/GNCSimulator/form";
import APP_ROUTES from "src/routes/routes";
import { createSimulator, getSimulatorOptions } from "src/services/simulator-gnc";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import { useTranslation } from "react-i18next";
import { SIMULATION_TYPE } from "src/constant/common";
import { transformDataVersionsToOptions } from "src/utils/common";

const NewGNCSimulator = () => {
  const { t } = useTranslation("gnc-simulator");
  const navigate = useNavigate();
  const showAlert = useSetRecoilState(alertState);
  const data = {
    [FORM_NAME.NAME]: "",
    [FORM_NAME.VERSION]: "",
    [FORM_NAME.NOTE]: "",
    [FORM_NAME.SERIES]: [DEFAULT_DATA_PARAMS],
  };

  const createSimulatorMutate = useMutation({
    mutationFn: ({ data }) => createSimulator({ data }),
  });

  const { data: versions } = useQuery(["getGNCVersions"], () => getSimulatorOptions(SIMULATION_TYPE["2D3F"]), {
    refetchOnWindowFocus: false,
  });

  const submitData = ({ data }, callback) =>
    createSimulatorMutate.mutate(
      { data },
      {
        onSuccess: () => {
          showAlert({
            status: true,
            message: t("new.create_success"),
            type: "success",
          });

          navigate(APP_ROUTES.GNC_SIMULATOR);
        },
        onSettled: () => callback(),
      }
    );

  return <FormGNCSimulator submitData={submitData} data={data} versions={transformDataVersionsToOptions(versions || [])} />;
};

export default routeGuard(NewGNCSimulator);
