import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import APP_ROUTES from "src/routes/routes";
import logo from "src/assets/images/logo.png";
import { useRecoilState, useSetRecoilState } from "recoil";
import { sidebarState } from "src/recoil/atoms/sidebar";
import ThrustIcon from "src/assets/images/thrust_icon.svg";
import GncIcon from "src/assets/images/gnc.svg";
import { resetFormPromptState } from "src/recoil/atoms/global";

const SidebarHeader = ({ children, needReset, ...rest }) => {
  const { t } = useTranslation("topbar");
  const setResetFormState = useSetRecoilState(resetFormPromptState);

  return (
    <div className="sidebar-header m-b-3 m-t-3">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link
          to={APP_ROUTES.HOME}
          onClick={() => {
            if (needReset) {
              setResetFormState(true);
            }
          }}
        >
          <img src={logo} alt="" height="48" />
        </Link>
        <div className="fw-bold m-l-10" style={{ textWrap: "nowrap" }}>
          {t("title")}
        </div>
      </div>
    </div>
  );
};

const SidebarMenu = () => {
  const { t } = useTranslation("sidebar");
  const [sidebar, setSidebar] = useRecoilState(sidebarState);
  const [broken, setBroken] = useState(false);
  const location = useLocation();
  const isMenuItemActive = (to) => location.pathname === to;
  const setResetFormState = useSetRecoilState(resetFormPromptState);

  useEffect(() => {
    setSidebar({ ...sidebar, broken: broken });
  }, [broken]);

  const firstSubMenuItems = [
    { to: APP_ROUTES.AERODYNAMICS, label: t("ar11") },
    { to: "", label: t("ta10") },
    { to: "", label: t("lv10") },
    { to: "", label: t("cf10") },
  ];

  const testAnalysisSubMenuItems = [
    { to: APP_ROUTES.TEST_ANALYSIS_LIST, label: t("ta10_isas") },
    { to: APP_ROUTES.WIND_TUNNEL_TEST_NAL, label: t("ta10_nal") },
  ];

  const secondSubMenuItems = [
    { to: APP_ROUTES.ENGINE_COMPONENT_LIST, label: t("ec10") },
    { to: APP_ROUTES.THRUST_CALCULATION_LIST, label: t("tc11") },
  ];

  const renderSubMenu = (menuList) =>
    menuList.map((item, index) =>
      item.to ? (
        <MenuItem key={index} component={<NavLink to={item.to} />}>
          {" "}
          {item.label}
        </MenuItem>
      ) : (
        <MenuItem key={index} disabled>
          {" "}
          {item.label}
        </MenuItem>
      )
    );

  return (
    <React.Fragment>
      <Sidebar
        collapsed={sidebar.collapsed}
        toggled={sidebar.toggled}
        onBackdropClick={() => setSidebar({ ...sidebar, toggled: false })}
        backgroundColor="white"
        onBreakPoint={setBroken}
        breakPoint="lg"
      >
        <SidebarHeader needReset={isMenuItemActive(APP_ROUTES.HOME)} />
        <Menu renderExpandIcon={({ open }) => <i className={`bi ${open ? "bi-chevron-down" : "bi-chevron-right"} fs-12`}></i>}>
          <MenuItem icon={<i className="bi bi-rocket fs-20"></i>} component={<NavLink to={APP_ROUTES.ROCKET_MODEL} />}>
            {" "}
            {t("ro10")}
          </MenuItem>
          <MenuItem
            icon={<i className="bi bi-rocket-takeoff fs-20"></i>}
            component={
              <NavLink
                to={APP_ROUTES.HOME}
                onClick={() => {
                  setResetFormState(true);
                }}
              />
            }
          >
            {" "}
            {t("fs10")}
          </MenuItem>
          <SubMenu
            icon={<i className="bi bi-graph-up fs-20"></i>}
            label={t("ta10")}
            className={`${sidebar.collapsed ? "hoverable-submenu" : ""}`}
            component={
              <NavLink
                to={APP_ROUTES.TEST_ANALYSIS_LIST}
                className={`${testAnalysisSubMenuItems.some((item) => isMenuItemActive(item.to)) ? "active" : ""}`}
              />
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {renderSubMenu(testAnalysisSubMenuItems)}
          </SubMenu>
          <SubMenu
            icon={<i className="bi bi-wind fs-20"></i>}
            label={t("ar10")}
            className={`${sidebar.collapsed ? "hoverable-submenu" : ""}`}
            component={
              <NavLink
                to={APP_ROUTES.AERODYNAMICS}
                className={`${firstSubMenuItems.some((item) => isMenuItemActive(item.to)) ? "active" : ""}`}
              />
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {renderSubMenu(firstSubMenuItems)}
          </SubMenu>
          <SubMenu
            icon={<img src={ThrustIcon} alt="thrust_icon" />}
            label={t("tc10")}
            className={sidebar.collapsed ? "hoverable-submenu" : ""}
            component={
              <NavLink
                to={APP_ROUTES.ENGINE_COMPONENT_LIST}
                className={`${secondSubMenuItems.some((item) => isMenuItemActive(item.to)) ? "active" : ""}`}
              />
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {renderSubMenu(secondSubMenuItems)}
          </SubMenu>

          <MenuItem
            icon={<img src={GncIcon} alt="gnc_icon" className="width-by-px-25 m-l-5-n" />}
            component={
              <NavLink
                to={APP_ROUTES.GNC_SIMULATOR}
                onClick={() => {
                  setResetFormState(true);
                }}
              />
            }
          >
            {t("gn10")}
          </MenuItem>
        </Menu>
      </Sidebar>
    </React.Fragment>
  );
};

export default SidebarMenu;
