import { useTranslation } from "react-i18next";
import { Button, Spinner, UncontrolledTooltip, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { useState } from "react";

const ButtonCollapse = ({ handleCollapse, isCollapsed }) => {
  return (
    <Button outline size="sm" className="px-1 btn-collapse height-by-px-36" onClick={handleCollapse}>
      <i className={`bi bi-chevron-${isCollapsed ? "down" : "up"} f-s-14 fw-bold text-dark`} />
    </Button>
  );
};

const SingleDashboard = ({ handleClick, isReady, isLoading, isShow = true, id }) => {
  const { t } = useTranslation("flight-simulator");
  return isShow ? (
    isLoading ? (
      <Spinner size="sm" color="secondary" className="mx-1" />
    ) : (
      <>
        <button
          className="btn btn-blank px-1 border-0 py-0"
          onClick={() => handleClick()}
          id={`dashboard-${id}`}
          onFocus={(e) => {
            // immediately blurs after focus
            e.target.blur();
          }}
        >
          <i className={`bi bi-speedometer2 ${isReady ? "text-success" : "text-dark"} f-s-20`} />
        </button>
        <UncontrolledTooltip target={`dashboard-${id}`}>{t("list.table.dashboard")}</UncontrolledTooltip>
      </>
    )
  ) : null;
};

const ButtonDownload = ({ url = "", id }) => {
  const { t } = useTranslation("flight-simulator");
  return !!url ? (
    <div>
      <a href={url} className="d-inline-block height-by-px-26" id={`download-${id}`}>
        <i className="bi bi-cloud-download f-s-20 text-dark" />
      </a>
      <UncontrolledTooltip target={`download-${id}`}>{t("list.table.download")}</UncontrolledTooltip>
    </div>
  ) : null;
};

const ButtonCopy = ({ isShow = true, toUrl, id }) => {
  const { t } = useTranslation("flight-simulator");
  return isShow ? (
    <>
      <Link to={toUrl} className="d-inline-block height-by-px-26" id={`copy-${id}`}>
        <i className="bi bi-copy f-s-20 text-dark" />
      </Link>
      <UncontrolledTooltip target={`copy-${id}`}>{t("list.table.copy")}</UncontrolledTooltip>
    </>
  ) : null;
};

const ButtonRetry = ({ isShow = true, toUrl, id }) => {
  const { t } = useTranslation("flight-simulator");
  return isShow ? (
    <>
      <Link to={toUrl} className="d-inline-block height-by-px-26" id={`retry-${id}`}>
        <i className="bi bi-arrow-clockwise f-s-20 text-dark" />
      </Link>
      <UncontrolledTooltip target={`retry-${id}`}>{t("new.retry")}</UncontrolledTooltip>
    </>
  ) : null;
};

const ButtonFileViewer = ({ handleClick, id }) => {
  const { t } = useTranslation("flight-simulator");
  return (
    <>
      <button
        className="btn btn-blank border-0 p-0"
        onClick={() => handleClick()}
        id={`fileview-${id}`}
        onFocus={(e) => {
          // immediately blurs after focus
          e.target.blur();
        }}
      >
        <i className="bi bi-file-earmark-text f-s-20 text-dark"></i>
      </button>
      <UncontrolledTooltip target={`fileview-${id}`}>{t("list.table.display_data")}</UncontrolledTooltip>
    </>
  );
};

const ButtonErrorWarning = ({ isShow = false, errorContent, id }) => {
  return isShow ? (
    <>
      <i className="bi text-danger bi-exclamation-triangle f-s-20 px-1" id={`serie-item-${id}`} />
      <UncontrolledTooltip target={`serie-item-${id}`} placement="right" popperClassName="right-tooltip">
        {errorContent}
      </UncontrolledTooltip>
    </>
  ) : null;
};

const ButtonResultDisplay = ({ isShow, handleClick, id }) => {
  const { t } = useTranslation("gnc-simulator");
  return isShow ? (
    <>
      <button
        className="btn btn-blank border-0 p-0"
        onClick={() => handleClick()}
        id={`result-display-${id}`}
        onFocus={(e) => {
          // immediately blurs after focus
          e.target.blur();
        }}
      >
        <i className="bi bi-ui-checks-grid f-s-20 text-dark"></i>
      </button>
      <UncontrolledTooltip target={`result-display-${id}`}>{t("list.table.display_data")}</UncontrolledTooltip>
    </>
  ) : null;
};

const ButtonTerminalSimulation = ({ isShow, handleSubmit, id }) => {
  const { t } = useTranslation("flight-simulator");
  const [toggle, setToggle] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  return isShow ? (
    <>
      <button
        className="btn btn-blank border-0 p-0"
        onClick={() => setToggle(true)}
        id={`terminal-button-${id}`}
        onFocus={(e) => {
          // immediately blurs after focus
          e.target.blur();
        }}
      >
        <i className="bi bi-stop-circle f-s-20 text-dark"></i>
      </button>
      <UncontrolledTooltip target={`terminal-button-${id}`}>{t("list.table.terminal")}</UncontrolledTooltip>

      <Modal isOpen={toggle} toggle={() => setToggle(false)} backdrop="static" size="md" centered>
        <ModalHeader>{t("list.table.terminal_popup_title")}</ModalHeader>
        <ModalBody>
          <div>{t("list.table.terminal_popup_content")}</div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary d-inline-block width-by-px-75" onClick={() => setToggle(false)}>
            {t("list.table.no")}
          </button>
          <button
            className="btn btn-danger d-inline-block width-by-px-75"
            onClick={() => {
              setIsSubmit(true);
              handleSubmit?.(id, () => {
                setIsSubmit(false);
                setToggle(false);
              });
            }}
            disabled={isSubmit}
          >
            {t("list.table.yes")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  ) : null;
};

export {
  ButtonCollapse,
  SingleDashboard,
  ButtonDownload,
  ButtonCopy,
  ButtonRetry,
  ButtonFileViewer,
  ButtonErrorWarning,
  ButtonResultDisplay,
  ButtonTerminalSimulation,
};
