import React, { useState, useEffect, useRef } from "react";
import {
  Collapse,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import HistoryLogs from "./HistoryLogs";
import EngineDetail from "./EngineDetail";

import { useNavigate, useParams, Link } from "react-router-dom";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { getRocketModelDetail, editRocketModel, getLogHistories, deleteRocketModel } from "src/services/rocket-model";
import EditableInput from "src/components/Common/Form/EditableInput";
import { getContentFromLink } from "src/services/simulator-flight";
import "react-json-view-lite/dist/index.css";
import {
  getOrdinalNumber,
  INIT_ROCKET_COMPONENT_DETAIL_COLUMNS,
  INIT_ROCKET_COMPONENT_DETAIL_ITEM,
  MODEL_DETAIL_TEMPLATE_CSV,
  PROCESS_STATUS,
  PROGRESS_STATUS,
  PROPERTIES_TEMPLATE,
  REFETCH_INTERVAL_TIME,
} from "src/constant/common";
import { useSetRecoilState } from "recoil";
import { alertState, navigationPromptState } from "src/recoil/atoms/global";
import DragAndDropTable from "src/components/Common/DragAndDropTable";

import { useMutation, useQuery } from "src/Hooks/useReactQuery";
import ModelDetail from "./ModelDetail";
import MassChart from "./Chart/Mass";
import { deepEqual, getChartData } from "src/utils/common";
import MomentChart from "./Chart/MomentIfInertia";
import APP_ROUTES from "src/routes/routes";
import Loading from "src/components/Common/Loading";
import LoadingWrapper from "src/components/Common/Table/Loading";
import { cloneDeep } from "lodash";
import { Tooltip } from "react-tooltip";
import { getComponentDetails, transformComponentDetailsData } from "src/utils/rocket-model";

const RocketModelDetail = ({ getRedirectURL }) => {
  const { id } = useParams();
  const { t } = useTranslation(["rocket-model", "common", "test-analysis"]);
  const navigate = useNavigate();
  const showAlert = useSetRecoilState(alertState);
  const navigationPrompt = useSetRecoilState(navigationPromptState);

  const [loading, setLoading] = useState(true);
  const [isOpenComponentDetail, setIsOpenComponentDetail] = useState(true);
  const [loadingHistories, setLoadingHistories] = useState(true);
  const [runningSimulation, setRunningSimulation] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isProgressing, setIsProgressing] = useState(false);

  const [isOpenStatusDD, setIsOpenStatusDD] = useState(false);
  const [isOpenImportDD, setIsOpenImportDD] = useState(false);
  const [progressStatus, setProgressStatus] = useState(PROGRESS_STATUS.FIXED);
  const [componentDetails, setComponentDetails] = useState([]);
  const [originalComponentDetails, setOriginalComponentDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteComponent, setIsModalDeleteComponent] = useState(false);
  const [data, setData] = useState({});
  const [modelDetail, setModelDetail] = useState({});
  const [massChart, setMassChart] = useState({ data: [], labels: [] });
  const [momentChart, setMomentChart] = useState({ data: [], labels: [] });
  const [errors, setErrors] = useState({});
  const [simulationErrors, setSimulationErrors] = useState(null);
  const stateRef = useRef(componentDetails);
  const timeout = useRef();

  const { refetch: getLogHistoriesMutation, data: logs } = useQuery(
    [`getLogHistories${id}`],
    () =>
      getLogHistories(id, {
        page: 1,
        limit: -1,
      }),
    {
      onSettled: () => setLoadingHistories(false),
      refetchInterval: REFETCH_INTERVAL_TIME,
    }
  );

  const { mutate: deleteRocketModelMutation } = useMutation({
    mutationKey: (id) => `deleteRocketModel${id}`,
    mutationFn: ({ id }) => deleteRocketModel({ id }),
  });

  const statusItems = [PROGRESS_STATUS.IN_PROGRESS, PROGRESS_STATUS.FIXED];
  const statusById = {
    in_progress: { name: t("detail.in_progress"), icon: "bi bi-unlock" },
    fixed: { name: t("detail.fixed"), icon: "bi bi-lock" },
  };

  const getModelDetailCsvContent = (csvUrl) => {
    if (!csvUrl) return;
    getContentFromLink(csvUrl)
      .then((res) => {
        setModelDetail(res.data);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
      });
  };

  const fetchModelDetails = async (detail) => {
    if (!id) {
      return;
    }

    const modelDetails = detail ?? (await getRocketModelDetail(id));

    if (modelDetails?.response?.status === 404) {
      return navigate(APP_ROUTES.NOT_FOUND);
    }

    if (modelDetails?.result_paths?.json_url) {
      await fetch(modelDetails?.result_paths?.json_url)
        .then((response) => response.json())
        .then((responseJson) => {
          const massChart = getChartData(responseJson?.gross?.mass?.distribution);
          const momentChart = getChartData(
            responseJson?.gross?.tensor_of_inertia?.divided_distribution,
            responseJson?.gross?.tensor_of_inertia?.distribution
          );
          setMassChart(massChart);
          setMomentChart(momentChart);

          return responseJson;
        })
        .catch((error) => {
          console.error(error);
        });
    }
    if (modelDetails?.result_paths?.csv_url) {
      getModelDetailCsvContent(modelDetails.result_paths.csv_url);
    } else {
      setModelDetail(MODEL_DETAIL_TEMPLATE_CSV[modelDetails?.number_of_stage]);
    }

    if (modelDetails?.component_details) {
      setShowWarning(!!modelDetails.unprocessed_component_details);
    }
    let component_details = modelDetails.unprocessed_component_details ?? modelDetails?.component_details;

    component_details = component_details ?? INIT_ROCKET_COMPONENT_DETAIL_ITEM(modelDetails.number_of_stage);
    component_details = getComponentDetails(component_details, componentDetails);
    handleSetComponentDetail(component_details, true);
    setOriginalComponentDetails(component_details);

    if (modelDetails) {
      if (modelDetails?.simulator_status === PROCESS_STATUS.SUBMITTED || modelDetails?.simulator_status === PROCESS_STATUS.RUNNING) {
        setRunningSimulation(true);
        checkSimulationStatus();
      }

      setProgressStatus(modelDetails?.edit_status);
      setData({
        id: modelDetails?.id ?? id,
        name: `${modelDetails.name || ""}`,
        stage: modelDetails.number_of_stage || "",
        remarks: modelDetails.remarks || "",
        jsonUrl: modelDetails?.result_paths?.json_url,
        csvUrl: modelDetails?.result_paths?.csv_url,
      });

      setSimulationErrors(modelDetails?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchModelDetails();

    return () => clearTimeout(timeout.current);
  }, [id]);

  const handleDeleteModel = () => {
    setIsModalOpen(false);
    deleteRocketModelMutation(
      { id },
      {
        onSuccess: async () => {
          await navigationPrompt(false);
          showAlert({
            status: true,
            message: t("detail.delete_confirm"),
            type: "success",
          });
          navigate("/rocket-model");
        },
      }
    );
  };

  const handleSetComponentDetail = (data, notChange) => {
    const cloneData = cloneDeep(data);

    if (deepEqual(data, originalComponentDetails)) {
      setComponentDetails(cloneData);
      navigationPrompt(false);
      return;
    }
    setComponentDetails(cloneData);
    if (!notChange) {
      navigationPrompt(true);
    }
  };

  useEffect(() => {
    stateRef.current = componentDetails;
  }, [componentDetails]);

  const validateName = (newValue) => {
    if (!newValue) {
      return t("new.name_required");
    }
    if (newValue.length > 255) {
      return t("new.name_maxlength");
    }
    return null;
  };

  const saveValue = (fieldName, newValue) => {
    const updatedData = { [fieldName]: newValue };
    const newErrors = { ...errors };

    if (fieldName === "name") {
      const error = validateName(newValue);
      newErrors.name = error;
      setErrors(newErrors);

      // If there's an error, don't proceed with the API call
      if (error) return;
    }

    // Add other fields validations here if necessary

    editRocketModel({
      id,
      data: updatedData,
      callback: () => {
        getLogHistoriesMutation();
      },
    });
  };

  const handleSave = (fields, newValue) => {
    if (!fields) return;
    const params = fields.split("|");
    const levelType = params[0];
    const fieldName = params[1];
    const stageIndex = params[2];
    const level0Index = params[3];
    const level1Index = params[4];
    const componentIndex = params[5];

    const newData = cloneDeep(componentDetails);

    if (levelType === "stage") {
      newData[stageIndex][fieldName] = newValue;
    }
    if (levelType === "level0") {
      newData[stageIndex].children[level0Index][fieldName] = newValue;
    }
    if (levelType === "level1") {
      newData[stageIndex].children[level0Index].children[level1Index][fieldName] = newValue;
    }
    if (levelType === "component") {
      newData[stageIndex].children[level0Index].children[level1Index].children[componentIndex][fieldName] = newValue;
    }
    handleSetComponentDetail(transformComponentDetailsData(newData));
  };

  const handleAddMore = (level, indexStr) => {
    const indexList = indexStr.split("|");
    const stageIndex = indexList[0];
    const level0Index = indexList[1];
    const level1Index = indexList[2];
    const newData = cloneDeep(componentDetails);
    const currentStage = newData[stageIndex]?.name;

    if (level === "level0") {
      newData[stageIndex].children.push({
        name: `${parseInt(currentStage, 10)}${getOrdinalNumber(parseInt(currentStage, 10))} Stage Inert`,
        isOpen: true,
        type: "level",
        original_stage: parseInt(currentStage, 10),
        children: [
          {
            name: `Level ${parseInt(currentStage, 10)} name`,
            isOpen: true,
            type: "level",
            children: [
              {
                type: "component",
                name: "Component name",
                properties: PROPERTIES_TEMPLATE,
                ...INIT_ROCKET_COMPONENT_DETAIL_COLUMNS,
              },
            ],
          },
        ],
      });
    }
    if (level === "level1") {
      newData[stageIndex].children[level0Index].children.push({
        name: `Level ${parseInt(currentStage, 10)} name`,
        isOpen: true,
        type: "level",
        children: [
          {
            type: "component",
            name: "Component name",
            properties: PROPERTIES_TEMPLATE,
            ...INIT_ROCKET_COMPONENT_DETAIL_COLUMNS,
          },
        ],
      });
    }
    if (level === "component") {
      newData[stageIndex].children[level0Index].children[level1Index].children.push({
        type: "component",
        name: "Component name",
        properties: PROPERTIES_TEMPLATE,
        ...INIT_ROCKET_COMPONENT_DETAIL_COLUMNS,
      });
    }
    handleSetComponentDetail(transformComponentDetailsData(newData));
  };

  const handleDelete = (level, indexStr) => {
    setIsModalDeleteComponent({ level, indexStr });
  };

  const deleteComponent = () => {
    const { level, indexStr } = isModalDeleteComponent;

    const indexList = indexStr.split("|");
    const stageIndex = indexList[0];
    const level0Index = indexList[1];
    const level1Index = indexList[2];
    const componentIndex = indexList[3];
    const newData = cloneDeep(componentDetails);

    if (level === "level0") {
      newData[stageIndex].children.splice(level0Index, 1);
    }
    if (level === "level1") {
      newData[stageIndex].children[level0Index].children.splice(level1Index, 1);
    }
    if (level === "component") {
      newData[stageIndex].children[level0Index].children[level1Index].children.splice(componentIndex, 1);
    }
    handleSetComponentDetail(transformComponentDetailsData(newData));
    setIsModalDeleteComponent(false);
  };

  const handleCollapse = (level, indexStr) => {
    indexStr = String(indexStr);
    const indexList = indexStr.split("|");
    const stageIndex = indexList[0];
    const level0Index = indexList[1];
    const level1Index = indexList[2];
    const newData = cloneDeep(componentDetails);

    if (level === "stage") {
      newData[stageIndex].isOpen = !newData[stageIndex].isOpen;
    }
    if (level === "level0") {
      newData[stageIndex].children[level0Index].isOpen = !newData[stageIndex].children[level0Index].isOpen;
    }
    if (level === "level1") {
      newData[stageIndex].children[level0Index].children[level1Index].isOpen =
        !newData[stageIndex].children[level0Index].children[level1Index].isOpen;
    }
    handleSetComponentDetail(transformComponentDetailsData(newData), true);
  };

  const handleEndDrop = (level, dragItem, monitor) => {
    const dropResult = monitor.getDropResult();
    if ((!dropResult?.index && dropResult?.index !== 0) || dragItem.index === dropResult.index) return;
    const newData = stateRef.current;

    const fromIndexList = dragItem.index?.toString()?.split("|").map(Number);
    const toIndexList = dropResult.index?.toString()?.split("|").map(Number);

    const getFromItem = () => {
      if (level === "component")
        return newData[fromIndexList[0]].children[fromIndexList[1]].children[fromIndexList[2]].children.splice(fromIndexList[3], 1);
      if (level === "level1") return newData[fromIndexList[0]].children[fromIndexList[1]].children.splice(fromIndexList[2], 1);
      if (level === "level0") return newData[fromIndexList[0]].children.splice(fromIndexList[1], 1);
      if (level === "stage") return newData.splice(fromIndexList[0], 1);
    };

    const placeItemToNewPosition = (fromItem) => {
      if (level === "component")
        newData[toIndexList[0]].children[toIndexList[1]].children[toIndexList[2]].children.splice(toIndexList[3], 0, fromItem[0]);
      if (level === "level1") newData[toIndexList[0]].children[toIndexList[1]].children.splice(toIndexList[2], 0, fromItem[0]);
      if (level === "level0") newData[toIndexList[0]].children.splice(toIndexList[1], 0, fromItem[0]);
      if (level === "stage") newData.splice(toIndexList[0], 0, fromItem[0]);
    };

    const fromItem = getFromItem();
    placeItemToNewPosition(fromItem);

    handleSetComponentDetail(transformComponentDetailsData(newData));
  };

  const updateProperties = (nodes) => {
    return nodes.map((node) => {
      // Base properties for all nodes
      const baseProperties = {
        name: node.name,
        type: node.type,
        isOpen: node.isOpen,
        mass: node.mass,
        l_axis_position: node.l_axis_position,
        outer_diameter: node.outer_diameter,
        length: node.length,
        center_of_gravity_x: node.center_of_gravity_x,
        center_of_gravity_y: node.center_of_gravity_y,
        center_of_gravity_z: node.center_of_gravity_z,
      };

      if (node.children) {
        baseProperties.children = updateProperties(node.children);
      }

      if (node.type === "component") {
        baseProperties.properties = {
          mass: { unit: "kg", value: node.mass },
          length: { unit: "m", value: node.length },
          inner_diameter: { unit: "m", value: node.inner_diameter },
          outer_diameter: { unit: "m", value: node.outer_diameter },
          l_axis_position: node.l_axis_position,
          center_of_gravity: { unit: "m", value: [node.center_of_gravity_x, node.center_of_gravity_y, node.center_of_gravity_z] },
          moment_of_inertia: { unit: "kgm^2", value: [node.moment_of_inertia_x, node.moment_of_inertia_y, node.moment_of_inertia_z] },
          product_of_inertia: { unit: "kgm^2", value: [node.product_of_inertia_x, node.product_of_inertia_y, node.product_of_inertia_z] },
        };
      }

      return baseProperties;
    });
  };

  const updateComponentDetails = () => {
    const newData = [...componentDetails];
    return updateProperties(newData);
  };
  const checkSimulationStatus = async () => {
    try {
      const modelDetails = await getRocketModelDetail(id);
      if (modelDetails.simulator_status === PROCESS_STATUS.SUCCEEDED || modelDetails.simulator_status === PROCESS_STATUS.FAILED) {
        setRunningSimulation(false);
        getModelDetailCsvContent(modelDetails?.result_paths?.csv_url);
        const msgType = modelDetails.simulator_status === PROCESS_STATUS.SUCCEEDED ? "success" : "danger";
        const msgContent =
          modelDetails.simulator_status === PROCESS_STATUS.SUCCEEDED
            ? t("detail.run_simulation_success")
            : modelDetails?.error?.code
            ? modelDetails?.error?.message
            : t("detail.run_simulation_error");
        if (modelDetails.simulator_status === PROCESS_STATUS.SUCCEEDED) {
          fetchModelDetails(modelDetails);
        }
        if (modelDetails?.simulator_status === PROCESS_STATUS.FAILED) {
          setSimulationErrors(modelDetails?.error);
        }

        getLogHistoriesMutation();

        showAlert({
          status: true,
          message: msgContent,
          type: msgType,
        });

        return;
      }
      await new Promise((resolve) => (timeout.current = setTimeout(resolve, 5000)));
      await checkSimulationStatus();
    } catch (error) {
      showAlert({
        status: true,
        message: t("common:network_error"),
        type: "danger",
      });
    }
  };
  const handleRunSimulation = async () => {
    try {
      setRunningSimulation(true);
      const updatedData = updateComponentDetails();
      await editRocketModel({
        id,
        data: { component_details: updatedData, action: "run_simulation", edit_status: PROGRESS_STATUS.FIXED },
        callback: () => {
          getLogHistoriesMutation();
        },
        promise: true,
      });
      setProgressStatus(PROGRESS_STATUS.FIXED);
      navigationPrompt(false);
      setShowWarning(false);
      await checkSimulationStatus();
    } catch (error) {
      setRunningSimulation(false);
      showAlert({
        status: true,
        message: error?.response?.data?.error?.detail || error?.response?.data?.error?.message || t("detail.run_simulation_error"),
        type: "danger",
      });
    }
  };

  const handleUploadJson = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = getComponentDetails(JSON.parse(e.target.result), componentDetails);
          if (json.length !== data?.stage) {
            showAlert({
              status: true,
              message: t("detail.import_error"),
              type: "danger",
            });
            return;
          }

          handleSetComponentDetail(json);
        } catch (err) {
          showAlert({
            status: true,
            message: t("detail.import_error"),
            type: "danger",
          });
          return;
        }
      };
      reader.readAsText(file);

      const input = document.querySelector("#input-label");
      input.value = null;
    }
  };

  const handleSetProgressStatus = async (status) => {
    let data = { edit_status: status };

    if (status === PROGRESS_STATUS.FIXED) {
      data = { ...data, unprocessed_component_details: updateProperties(componentDetails) };
      navigationPrompt(false);
    }

    setIsProgressing(true);
    try {
      const detail = await editRocketModel({
        id,
        data,
      });
      if (detail?.data?.unprocessed_component_details && detail?.data?.component_details) {
        setShowWarning(true);
      }
      return setProgressStatus(status);
    } catch (error) {
      showAlert({
        status: true,
        message: t("common:network_error"),
        type: "danger",
      });
    } finally {
      setIsProgressing(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="container-fluid">
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={getRedirectURL()} className="link-primary">
            {t("list.title")}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("detail.title")}</BreadcrumbItem>
      </Breadcrumb>

      <div className="d-flex gap-3">
        <div className="card p-2 flex-fill">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td className="col-5">ID</td>
                <td>
                  <EditableInput fieldName="id" initialValue={data?.id} disabled />
                </td>
              </tr>
              <tr>
                <td className="col-5">{t("detail.name")}</td>
                <td>
                  <EditableInput fieldName="name" initialValue={data?.name} handleSave={saveValue} errors={errors?.name} />
                </td>
              </tr>
              <tr>
                <td className="col-5">{t("detail.stage")}</td>
                <td>
                  <EditableInput fieldName="number_of_stage" initialValue={data?.stage} disabled />
                </td>
              </tr>
              <tr>
                <td className="col-5">{t("detail.remarks")}</td>
                <td>
                  <EditableInput fieldName="remarks" initialValue={data?.remarks} handleSave={saveValue} showLabel type="textarea" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="card col-4 d-flex justify-content-center align-items-center p-2">
          {momentChart?.data?.length > 0 ? <MomentChart {...momentChart} /> : t("detail.empty")}
        </div>
        <div className="card col-4 d-flex justify-content-center align-items-center p-2">
          {massChart?.data?.length > 0 ? <MassChart {...massChart} /> : t("detail.empty")}
        </div>
      </div>

      <ModelDetail data={data} modelDetail={modelDetail} />

      <LoadingWrapper loading={runningSimulation} customClass="mh-100">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <button
                className="btn btn-blank text-dark border-0 p-0 header-title mb-0 text-nowrwap"
                onClick={() => setIsOpenComponentDetail(!isOpenComponentDetail)}
              >
                {t("detail.component_detail")} <i className={isOpenComponentDetail ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></i>
              </button>

              {showWarning && <div className="text-danger text-center text-prewrap fs-14">{t("detail.warning_content")}</div>}
              <div className="d-flex align-items-center gap-2">
                {simulationErrors?.code && (
                  <>
                    <i
                      className="bi text-danger bi-exclamation-triangle f-s-20"
                      data-tooltip-id={`rocket-error-${id}`}
                      data-tooltip-content={` ${simulationErrors?.message ?? t("detail.run_simulation_error")}\n${simulationErrors?.errors
                        ?.map((i) => `- [${i?.error_id}] ${i?.message}`)
                        ?.join("\n")}`}
                    />
                    <Tooltip id={`rocket-error-${id}`} className="text-break-space z-i-1009" />
                  </>
                )}
                <Dropdown isOpen={isOpenStatusDD} toggle={() => setIsOpenStatusDD(!isOpenStatusDD)}>
                  <DropdownToggle color="primary" className="width-by-px-150" disabled={isProgressing}>
                    <i className={statusById[progressStatus].icon}></i> {statusById[progressStatus].name}
                    <i className="bi bi-caret-down-fill"></i>
                  </DropdownToggle>
                  <DropdownMenu className="topbar-dropdown-menu profile-dropdown-items">
                    {statusItems.map((id) => (
                      <DropdownItem key={id} onClick={() => handleSetProgressStatus(id)}>
                        <i className={statusById[id].icon}></i> {statusById[id].name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <Dropdown isOpen={isOpenImportDD} toggle={() => setIsOpenImportDD(!isOpenImportDD)}>
                  <DropdownToggle color="primary" disabled={progressStatus === PROGRESS_STATUS.FIXED}>
                    {t("detail.import")} <i className="bi bi-caret-down-fill"></i>
                  </DropdownToggle>
                  <DropdownMenu className="topbar-dropdown-menu profile-dropdown-items">
                    <DropdownItem disabled>{t("detail.csv_import")}</DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        const input = document.getElementById("input-label");
                        input.click();
                      }}
                    >
                      {t("detail.json_import")}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <button className="btn btn-primary width-by-px-150" onClick={handleRunSimulation}>
                  {t("detail.run_simulation")}
                </button>
              </div>
            </div>
          </div>

          <Collapse isOpen={isOpenComponentDetail} className="table-drag">
            <DragAndDropTable
              isFixed={progressStatus === PROGRESS_STATUS.FIXED}
              totalStage={data?.stage}
              {...{
                data: componentDetails,
                handleSave,
                handleAddMore,
                handleCollapse,
                handleEndDrop,
                handleDelete,
                initComponentColumn: INIT_ROCKET_COMPONENT_DETAIL_COLUMNS,
              }}
            />
          </Collapse>
        </div>
      </LoadingWrapper>

      <EngineDetail
        id={data?.id || id}
        stage={data?.stage}
        getLogs={() => {
          getLogHistoriesMutation();
        }}
      />

      <HistoryLogs id={id} logs={logs} loadingHistories={loadingHistories} stage={data?.stage} />

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <div>
            <Button label={t("detail.delete_rocket")} icon="pi pi-trash" className="btn btn-danger" onClick={() => setIsModalOpen(true)} />
          </div>
        </div>
      </div>
      <input id="input-label" type="file" className="hidden-input" accept="application/JSON" onChange={(e) => handleUploadJson(e)} />

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} backdrop="static" centered>
        <ModalHeader>{t("detail.delete_rocket_confirm")}</ModalHeader>
        <ModalBody>
          <div>{t("detail.confirm_delete")}</div>
          <div className="text-danger">{t("detail.confirm_delete_2")}</div>
        </ModalBody>
        <ModalFooter>
          <span className="btn btn-secondary btn-sm" role="button" onClick={() => setIsModalOpen(false)}>
            {t("detail.cancel")}
          </span>
          <span className="btn btn-danger btn-sm" role="button" onClick={() => handleDeleteModel()}>
            {t("detail.delete")}
          </span>
        </ModalFooter>
      </Modal>

      <Modal isOpen={!!isModalDeleteComponent} toggle={() => setIsModalDeleteComponent(false)} backdrop="static" centered>
        <ModalHeader>{t("detail.delete")}</ModalHeader>
        <ModalBody>
          <div>{t("detail.confirm_delete")}</div>
          <div className="text-danger">{t("detail.confirm_delete_2")}</div>
        </ModalBody>
        <ModalFooter>
          <span className="btn btn-secondary btn-sm" role="button" onClick={() => setIsModalDeleteComponent(false)}>
            {t("detail.cancel")}
          </span>
          <span className="btn btn-danger btn-sm" role="button" onClick={() => deleteComponent()}>
            {t("detail.delete")}
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RocketModelDetail;
