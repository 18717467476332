import axios from "axios";
import api from "src/configs/api";
import {
  CREATE_GNC_SIMULATOR,
  EDIT_GNC_SIMULATOR,
  GET_GNC_SIMULATOR,
  GNC_SIMULATOR_DETAIL,
  GET_GNC_SIMULATOR_OPTIONS,
  GET_GNC_SERIES_INPUT,
  GET_GNC_SERIES_DASHBOARD,
  GET_GNC_CASES_RESULT,
} from "src/constant/endpoint";

const getSimulators = async (params) => await api.get(GET_GNC_SIMULATOR, { params }).then((response) => response?.data?.data);

const getSimulatorsDetail = async (id) => await api.get(GNC_SIMULATOR_DETAIL(id)).then((response) => response?.data?.data);

const createSimulator = ({ data }) => api.post(CREATE_GNC_SIMULATOR, { data }).then((response) => response?.data);

const editSimulator = ({ data }) => api.put(EDIT_GNC_SIMULATOR(data.id), { data }).then((response) => response?.data);

const getSimulatorOptions = async (type) => await api.get(GET_GNC_SIMULATOR_OPTIONS(type)).then((response) => response?.data?.data);

const getSeriesInput = async (id) => {
  return await api.get(GET_GNC_SERIES_INPUT(id)).then((response) => response?.data?.data);
};

const getContentFromLink = async (link) => axios.get(link);

const getDashboardLink = async (data) => {
  const { id, ...params } = data;
  return await api.post(GET_GNC_SERIES_DASHBOARD(id), { data: params });
};

const getCasesResult = async ({ id }) => await api.get(GET_GNC_CASES_RESULT(id)).then((response) => response?.data?.data);

export {
  createSimulator,
  editSimulator,
  getSimulatorOptions,
  getSimulators,
  getSimulatorsDetail,
  getSeriesInput,
  getContentFromLink,
  getDashboardLink,
  getCasesResult,
};
