import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { DASHBOARD_TYPE, DASHBOARD_URL_BY_TYPE } from "src/constant/common";

const SeriesDashboard = ({
  handleClickFlight,
  handleClickSensitivity,
  handleClickDispersionError,
  handleClickDispersionRss,
  id,
  isLoading,
  isShow = true,
  serieData,
  isSensitivityAnalysis,
  isDispersionAnalysis,
  getDashboardItemByType,
}) => {
  const { t } = useTranslation("flight-simulator");

  const isDashboardUrlAvailable = (type) => {
    const dashboardItem = getDashboardItemByType(serieData, type);
    if (dashboardItem?.[DASHBOARD_URL_BY_TYPE[type]]) {
      return true;
    }
    return false;
  }

  const isMultiDashboardAvailable = () => isDashboardUrlAvailable(DASHBOARD_TYPE.MAIN) && (
    isDashboardUrlAvailable(DASHBOARD_TYPE.SENSITIVITY) || (
      isDashboardUrlAvailable(DASHBOARD_TYPE.DISPERSION_ERROR) &&
      isDashboardUrlAvailable(DASHBOARD_TYPE.DISPERSION_RSS)
    )
  );

  return (
    isShow ? (
      isLoading ? (
        <Spinner size="sm" color="secondary" className="mx-1" />
      ) : (
        <>
          <UncontrolledDropdown
            className="align-self-center"
            id={`dashboard-serie-${id}`}
            direction="end"
          >
            <DropdownToggle
              data-toggle="dropdown"
              tag="button"
              className="border-0 bg-transparent"
            >
              <i className={`bi bi-speedometer2 ${isMultiDashboardAvailable() ? 'text-success' : 'text-dark'} f-s-20`} />
            </DropdownToggle>

            <DropdownMenu container=".navbar">
              <div
                role="button"
                className={`dropdown-item ${isDashboardUrlAvailable(DASHBOARD_TYPE.MAIN) ? 'text-success' : 'text-dark'}`}
                onClick={handleClickFlight}
              >
                <span>{t('list.table.flight_analysis_dashboard')}</span>
              </div>
              {isSensitivityAnalysis && (
                <div
                  role="button"
                  className={`dropdown-item ${isDashboardUrlAvailable(DASHBOARD_TYPE.SENSITIVITY) ? 'text-success' : 'text-dark'}`}
                  onClick={handleClickSensitivity}
                >
                  <span>{t('list.table.sensitivity_analysis_dashboard')}</span>
                </div>
              )}

              {isDispersionAnalysis && (
                <>
                  <div
                    role="button"
                    className={`dropdown-item ${isDashboardUrlAvailable(DASHBOARD_TYPE.DISPERSION_ERROR) ? 'text-success' : 'text-dark'}`}
                    onClick={handleClickDispersionError}
                  >
                    <span>{t('list.table.dispersion_error_dashboard')}</span>
                  </div>
                  <div
                    role="button"
                    className={`dropdown-item ${isDashboardUrlAvailable(DASHBOARD_TYPE.DISPERSION_RSS) ? 'text-success' : 'text-dark'}`}
                    onClick={handleClickDispersionRss}
                  >
                    <span>{t('list.table.dispersion_rss_dashboard')}</span>
                  </div>
                </>
              )}
            </DropdownMenu>

          </UncontrolledDropdown>
          <UncontrolledTooltip
            target={`dashboard-serie-${id}`}
          >
            {t("list.table.dashboard")}
          </UncontrolledTooltip>
        </>
      )
    ) : null
  );
};

export default SeriesDashboard;
