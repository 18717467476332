import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, Button } from "reactstrap";
import useUrlState from "@ahooksjs/use-url-state";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import { getTestAnalysis } from "src/services/nal-test-analysis";
import { PAGINATION_DEFAULT, PROCESS_STATUS, ALLOW_EDIT_STATUS, REFETCH_INTERVAL_TIME } from "src/constant/common";
import { handleScrollTo, getFileName, handleScrollToTop } from "src/utils/common";
import { useQuery } from "src/Hooks/useReactQuery";
import ModalDeleteCsv from "src/components/Common/Modal/TestAnalysis/Nal/DeleteCSV";
import ModelAddTestAnalysisNal from "src/components/Common/Modal/TestAnalysis/Nal/AddTestAnalysis";
import TableCommon from "src/components/Common/Table";
import LabelStatus from "src/components/Common/LabelStatus";
import { Tooltip } from "react-tooltip";
import ModalAddTestAnalysisItem from "src/components/Common/Modal/TestAnalysis/Nal/AddItems";
import ShowDataCell from "src/components/Common/DataCell";

const WindTunnelTestList = () => {
  const { t } = useTranslation(["wind-tunnel-test-nal", "common"]);

  const [params, setParams] = useUrlState(
    {},
    {
      navigateMode: "replace",
      parseOptions: {
        parseNumbers: true,
      },
      stringifyOptions: {
        skipNull: true,
      },
    }
  );

  const [isLoading, setIsLoading] = useState(true);
  const [allowScrollToId, setAllowScrollToId] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [removeData, setRemoveData] = useState();
  const [isAddNew, setIsAddNew] = useState(false);
  const showAlert = useSetRecoilState(alertState);

  const { data: dataTestAnalysis, refetch } = useQuery(
    ["getTestAnalysis"],
    () =>
      getTestAnalysis({
        page: params.page,
        limit: PAGINATION_DEFAULT.pageSize,
        q: params?.condition,
      }),
    {
      onSuccess: (data) => {
        for (const testItem of data.items) {
          testItem.items.sort((a, b) => (a.type > b.type ? -1 : b.type > a.type ? 1 : 0));
          if (testItem.analysis_status === PROCESS_STATUS.SUCCEEDED) {
            const haveFailedItem = testItem.items.some((item) => item.error_message);
            testItem.analysis_status = haveFailedItem ? PROCESS_STATUS.FAILED : testItem.analysis_status;
          }
          if (!testItem.items.length) {
            testItem.analysis_status = PROCESS_STATUS.PENDING;
          }
        }
        if (params?.id && allowScrollToId) {
          handleScrollTo(0, 90, `row${params.id}`);
          setAllowScrollToId(false);
        }

        if (isAddNew) {
          showAlert({
            status: true,
            message: t("new.create_success"),
            type: "success",
          });
          setIsAddNew(false);
        }
      },
      onSettled: () => setIsLoading(false),
      refetchInterval: REFETCH_INTERVAL_TIME,
    }
  );

  const columnsCollapse = [
    {
      dataField: "",
      text: "",
      headerClass: "width-by-px-20",
    },
    {
      dataField: "id",
      text: t("list.table.test_item_id"),
      headerClass: "width-by-px-75",
    },
    {
      dataField: "roll_angle",
      text: t("list.table.roll_angle"),
      headerClass: "width-by-px-178",
      rowClass: "col-fixed",
      formatter: (_, row) => <>{row.config.roll_angle != null ? row.config.roll_angle : "-"}</>,
    },
    {
      dataField: "no_wind_result",
      text: t("list.table.no_wind_result"),
      headerClass: "width-by-percent-21",
      rowClass: "col-fixed",
      formatter: (_, row, { parentRow }) => (
        <div className="w-100">{parentRow?.isCollapse && <ShowDataCell data={getFileName(row.config?.no_wind_path) || "-"} />}</div>
      ),
    },
    {
      dataField: "blow_result",
      text: t("list.table.blow_result"),
      headerClass: "width-by-percent-21",
      rowClass: "col-fixed",
      formatter: (_, row, { parentRow }) => (
        <div className="w-100">{parentRow?.isCollapse && <ShowDataCell data={getFileName(row.config?.blow_path) || "-"} />}</div>
      ),
    },
    {
      dataField: "atmospheric_data",
      text: t("list.table.atmospheric_data"),
      headerClass: "width-by-percent-21",
      rowClass: "col-fixed",
      formatter: (_, row, { parentRow }) => (
        <div className="w-100">
          {parentRow?.isCollapse && <ShowDataCell data={getFileName(row.config?.atmospheric_pressure_data_path) || "-"} />}
        </div>
      ),
    },
    {
      dataField: "coefficients_data",
      text: t("list.table.coefficients_data"),
      headerClass: "width-by-percent-21",
      rowClass: "col-fixed",
      formatter: (_, row, { parentRow }) => (
        <div className="w-100">{parentRow?.isCollapse && <ShowDataCell data={getFileName(row?.calculated_path) || "-"} />}</div>
      ),
    },
    {
      dataField: "action",
      text: "",
      headerClass: "w-auto",
      formatter: (_, row, { parentIndex, parentRow }) => (
        <div className="d-flex align-items-center justify-content-end min-height-by-px-30">
          {row.error_message && (row.result_path || row.error_message) ? (
            <>
              <i
                className="bi text-danger bi-exclamation-triangle f-s-16"
                data-tooltip-id={`ta-item-${parentRow?.id}-${parentIndex}`}
                data-tooltip-content={row.error_message}
              />
              <Tooltip id={`ta-item-${parentRow?.id}-${parentIndex}`} className="text-break-space" />
            </>
          ) : null}
          {checkConditionShowDeleteItemButton(row) && (
            <>
              <button
                className="btn btn-blank p-0 border-0 m-l-15"
                disabled={!ALLOW_EDIT_STATUS.includes(parentRow?.analysis_status)}
                onClick={() => confirmDeleteFile(row)}
                data-tooltip-id={`ta-item-delete-${parentRow?.id}-${parentIndex}`}
                data-tooltip-content={t("new.delete_modal.delete")}
              >
                <i className="bi bi-trash text-danger f-s-20" />
              </button>
              <Tooltip id={`ta-item-delete-${parentRow?.id}-${parentIndex}`} className="text-break-space" />
            </>
          )}
        </div>
      ),
    },
  ];

  const columns = [
    {
      dataField: "id",
      text: t("list.table.id"),
      headerClass: "text-nowrwap width-by-px-75 position-sticky left-0px z-i-1 bg-white",
      rowClass: "position-sticky left-0px z-i-1 ",
    },
    {
      dataField: "name",
      text: t("list.table.name"),
      headerClass: "text-nowrwap width-by-px-250 position-sticky left-75px-rem z-i-1 bg-white",
      rowClass: "position-sticky left-75px-rem z-i-1 ",
      formatter: (_, row) => <ShowDataCell data={row.name} />,
    },
    {
      dataField: "status",
      text: t("list.table.status"),
      headerClass: "text-nowrwap width-by-px-130 position-sticky left-325px-2rem z-i-1 bg-white",
      rowClass: "position-sticky left-325px-2rem z-i-1 ",
      formatter: (_, row) => (
        <LabelStatus
          row={row}
          field={row.analysis_status === PROCESS_STATUS.SUCCEEDED ? "dashboard_status" : "analysis_status"}
          staticTooltip={row.error_message}
        />
      ),
    },
    {
      dataField: "process",
      text: t("list.table.process"),
      headerClass: "text-nowrwap min-width-by-px-150",
      formatter: (_, row) => <div className="min-width-by-px-150">{row.process.name}</div>,
    },
    {
      dataField: "representative_surface_areas",
      text: t("list.table.representative_surface_areas"),
      headerClass: "text-nowrwap min-width-by-px-150",
      formatter: (_, row) => <div className="min-width-by-px-150">{row.config.representative_surface_areas}</div>,
    },
    {
      dataField: "representative_length",
      text: t("list.table.representative_length"),
      headerClass: "text-nowrwap min-width-by-px-100",
      rowClass: "text-nowrwap",
      formatter: (_, row) => <div className="min-width-by-px-100">{row.config.representative_length}</div>,
    },
    {
      dataField: "air_flow",
      text: t("list.table.air_flow"),
      headerClass: "text-nowrwap min-width-by-px-150",
      formatter: (_, row) => <div className="min-width-by-px-150">{row.config.test_airflow_settling}</div>,
    },
    {
      dataField: "base_area",
      text: t("list.table.base_area"),
      headerClass: "text-nowrwap min-width-by-px-100",
      formatter: (_, row) => <div className="min-width-by-px-100">{row.config.base_area}</div>,
    },
    {
      dataField: "cavity_area",
      text: t("list.table.cavity_area"),
      headerClass: "text-nowrwap min-width-by-px-100",
      formatter: (_, row) => <div className="min-width-by-px-100">{row.config.cavity_area}</div>,
    },
    {
      dataField: "aoa_classification_unit",
      text: t("list.table.aoa_classification_unit"),
      headerClass: "text-nowrwap min-width-by-px-150",
      formatter: (_, row) => <div className="min-width-by-px-150">{row.config.angle_of_attack_classification_unit}</div>,
    },
    {
      dataField: "min_aoa",
      text: t("list.table.min_aoa"),
      headerClass: "text-nowrwap min-width-by-px-130",
      formatter: (_, row) => <div className="min-width-by-px-130">{row.config.min_angle_of_attack}</div>,
    },
    {
      dataField: "max_aoa",
      text: t("list.table.max_aoa"),
      headerClass: "text-nowrwap min-width-by-px-130",
      formatter: (_, row) => <div className="min-width-by-px-130">{row.config.max_angle_of_attack}</div>,
    },
    {
      dataField: "error_aoa",
      text: t("list.table.error_aoa"),
      headerClass: "text-nowrwap min-width-by-px-150",
      formatter: (_, row) => <div className="min-width-by-px-150">{row.config.error_range_of_angle_of_attack}</div>,
    },
    {
      dataField: "original_data",
      text: t("list.table.original_data"),
      headerClass: "text-nowrwap min-width-by-px-90 position-sticky right-75px-rem z-i-1 bg-white",
      rowClass: "position-sticky right-75px-rem z-i-1",
      formatter: (_, row, { isCollapse, toggleCollapse }) => (
        <div className="d-flex justify-content-between align-items-center min-width-by-px-90">
          <span className="m-r-5">
            {row.items.length} {row.items.length > 1 ? "datas" : "data"}
          </span>
          {row.items.length ? (
            <Button outline size="sm" className="px-1 btn-collapse height-by-px-36" onClick={() => toggleCollapse()}>
              <i className={`bi bi-chevron-${isCollapse ? "up" : "down"} f-s-14 fw-bold text-dark`} />
            </Button>
          ) : null}
        </div>
      ),
      collapseRow: (data, { isCollapse }) => (
        <div className="w-100">
          {data.items?.length > 0 && (
            <TableCommon
              columns={columnsCollapse}
              data={data.items}
              parentRow={{ ...data, isCollapse }}
              tableClassName="table-collapse mb-0"
              customClassName="overflow-auto simulation-table table-detail"
              classTableHead="table-secondary"
              hasPagination={false}
              tableFixed
            />
          )}
        </div>
      ),
    },
    {
      dataField: "",
      text: "",
      headerClass: "text-nowrwap min-width-by-px-75 position-sticky right-0px z-i-1 bg-white",
      rowClass: "position-sticky right-0px z-i-1",
      formatter: (_, row, { isCollapse, toggleCollapse }) => (
        <div className="d-flex justify-content-end gap-2">
          {checkConditionShowDashboardButton(row) ? (
            <>
              <Link
                to={row.dashboard_url}
                target="_blank"
                data-tooltip-id={`dashboard-${row?.id}`}
                data-tooltip-content={t("list.table.dashboard")}
              >
                <i className={`bi bi-speedometer2 f-s-20 text-success`} />
              </Link>
              <Tooltip id={`dashboard-${row?.id}`} className="text-break-space" />
            </>
          ) : (
            <div />
          )}
          <ModalAddTestAnalysisItem
            onSuccess={async () => {
              await refetch();
              if (!isCollapse) {
                toggleCollapse();
              }
              showAlert({
                status: true,
                message: t("new.create_item_success"),
                type: "success",
              });
            }}
            testAnalysisData={row}
          />
        </div>
      ),
    },
  ];

  const checkConditionShowDashboardButton = (row) => {
    const haveValidItem = row.items.some((item) => !item.error_message);
    return (
      row.dashboard_url &&
      (row.analysis_status === PROCESS_STATUS.SUCCEEDED || row.analysis_status === PROCESS_STATUS.FAILED) &&
      haveValidItem
    );
  };

  const checkConditionShowDeleteItemButton = (rowItem) => rowItem.result_path || rowItem.error_message || rowItem.calculated_path;

  const confirmDeleteFile = (data) => {
    setToggle(true);
    setRemoveData(data);
  };

  useEffect(() => {
    setIsLoading(true);
    refetch();
  }, [params]);

  const onChangePage = (value) => {
    setParams({
      ...params,
      page: value,
    });
    handleScrollToTop();
  };

  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="header-title mb-0">{t("list.title")}</h2>
              </div>
              <Row>
                <Col md={12}>
                  <Card>
                    <div className="d-flex justify-content-end">
                      <CardHeader className="bg-white border-0">
                        <ModelAddTestAnalysisNal
                          onSuccess={() => {
                            setIsAddNew(true);
                            if (params?.page === 1) {
                              setIsLoading(true);
                              refetch();
                              return;
                            }
                            setParams({ ...params, page: 1 });
                          }}
                        />
                      </CardHeader>
                    </div>
                    <CardBody className="bg-white">
                      <TableCommon
                        loading={isLoading}
                        columns={columns}
                        data={dataTestAnalysis?.items || []}
                        onChangePage={onChangePage}
                        tableClassName="striped table-collapse table-bg"
                        customClassName="overflow-auto simulation-table"
                        pageCurrent={(params?.page || PAGINATION_DEFAULT.page) - 1}
                        pageCount={dataTestAnalysis?.pagination?.total_page}
                        totalItem={dataTestAnalysis?.pagination?.total}
                        pageSize={PAGINATION_DEFAULT.pageSize}
                        headerWrapDiv
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
      <ModalDeleteCsv toggle={toggle} closeModal={() => setToggle(false)} data={removeData} onDelete={refetch} />
    </Container>
  );
};

export default WindTunnelTestList;
