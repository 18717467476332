import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "src/Hooks/useReactQuery";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LoadingWrapper from "src/components/Common/Table/Loading";
import { getCasesResult, getDashboardLink } from "src/services/simulator-gnc";
import { SingleDashboard } from "src/components/Common/Button";
import { DASHBOARD_INPUT_TYPE, DASHBOARD_STATUS, DASHBOARD_TYPE, RETRY_TIME } from "src/constant/common";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import classNames from "classnames";

const ModalCaseResult = ({ id, closeModal, isOpen }) => {
  const [loading, setLoading] = useState(true);
  const [dashboardLoading, setDashboardLoading] = useState({});
  const { t } = useTranslation(["gnc-simulator", "common"]);
  const showAlert = useSetRecoilState(alertState);
  const KEY_NOT_SHOW = ["input_file_id", "case_id"];

  const { data, refetch } = useQuery(
    [`getCasesResult_${id}`],
    () =>
      getCasesResult({
        id,
      }),
    {
      onSuccess: () => {},
      onSettled: () => setLoading(false),
    }
  );

  const checkDashboardUrl = async (simulationData, params, retryCount, case_id) => {
    try {
      const res = await getDashboardLink(params);
      if (res.data.status === DASHBOARD_STATUS.INPROGRESS) {
        if (retryCount === RETRY_TIME) {
          showAlert({
            status: true,
            message: t("over_time"),
            type: "danger",
          });
          setDashboardLoading((oldVal) => ({ ...oldVal, [`${params.input_file_id}_${case_id}`]: false }));
          return;
        }
        await new Promise((resolve) => setTimeout(resolve, 3000));
        retryCount += 1;
        await checkDashboardUrl(simulationData, params, retryCount);
        return;
      }
      await refetch();
      setDashboardLoading((oldVal) => ({ ...oldVal, [`${params.input_file_id}_${case_id}`]: false }));
      if (res.data.dashboard_status === DASHBOARD_STATUS.SUCCEEDED) {
        window.open(res.data.dashboard_url, "_blank");
        return;
      }
    } catch (e) {
      setDashboardLoading((oldVal) => ({ ...oldVal, [`${params.input_file_id}_${case_id}`]: false }));
      showAlert({
        status: true,
        message: e?.response?.data?.error?.message || e?.response?.data?.message || t("common:network_error"),
        type: "danger",
      });
    }
  };

  const handleClickDashboard = async (row) => {
    const dashboard = row?.simulator_dashboard;
    if (dashboard?.dashboard_status === DASHBOARD_STATUS.SUCCEEDED && dashboard?.dashboard_url) {
      window.open(dashboard?.dashboard_url, "_blank");
      return;
    }
    setDashboardLoading({ ...dashboardLoading, [`${row.input_file_id}_${row?.case_id}`]: true });
    const data = {
      id,
      dashboard_input_type: DASHBOARD_INPUT_TYPE.SINGLE,
      dashboard_type: `${DASHBOARD_TYPE.GNC_SINGLE}_case_${row?.case_id}`,
      input_file_id: row?.input_file_id,
    };

    await checkDashboardUrl(dashboard, data, 1, row?.case_id);
  };

  const filterKeys = data?.length > 0 ? Object.keys(data[0]).filter((key) => data.some((row) => typeof row[key] !== "object")) : [];

  return (
    <Modal centered isOpen={isOpen} toggle={closeModal} className="modal-full-width" backdrop="static">
      <ModalHeader className="p-b-0" toggle={closeModal}>
        {t("list.table.result_button")}
      </ModalHeader>
      <ModalBody>
        <LoadingWrapper loading={loading}>
          <div
            className={classNames("react-bootstrap-table table-detail overflow-auto w-100 h-100 max-vh-85", {
              "min-height-by-px-110": loading,
            })}
          >
            <table className="table table-striped table-hover table-bordered mb-0 table-collapse">
              <thead className="table-secondary">
                <tr>
                  {data?.length && <th>{t("list.table.dashboard")}</th>}
                  {filterKeys?.map((key, index) => {
                    if (KEY_NOT_SHOW.includes(key)) return <Fragment key={index}></Fragment>;
                    return (
                      <th key={index}>
                        <div className="text-nowrap">{key}</div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="text-center height-by-px-60">
                      <SingleDashboard
                        id={row?.input_file_id}
                        isShow={true}
                        isLoading={dashboardLoading?.[`${row.input_file_id}_${row?.case_id}`]}
                        handleClick={() => handleClickDashboard(row)}
                        isReady={!!row.simulator_dashboard?.dashboard_url}
                      />
                    </td>

                    {filterKeys?.map((key, colIndex) => {
                      if (KEY_NOT_SHOW.includes(key)) return <Fragment key={colIndex}></Fragment>;
                      return (
                        <td key={colIndex} className="text-nowrwap">
                          {row[key]}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </LoadingWrapper>
      </ModalBody>
    </Modal>
  );
};

export default ModalCaseResult;
