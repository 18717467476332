import api from "src/configs/api";
import {
  GET_NAL_TEST_ANALYSIS,
  GET_NAL_TEST_ANALYSIS_PROCESS,
  CREATE_NAL_TEST_ANALYSIS,
  DELETE_NAL_TEST_ANALYSIS_ITEM,
  POST_NAL_TEST_ANALYSIS_ITEM,
} from "src/constant/endpoint";

const getTestAnalysis = async (params) =>
  await api.get(GET_NAL_TEST_ANALYSIS, { params }).then((response) => {
    response?.data?.data?.items?.map((item) => {
      item.analysis_status = item.analysis_status === "not_yet" ? "PENDING" : item.analysis_status.toUpperCase();
      return item;
    });
    return response?.data?.data;
  });

const createTestAnalysis = async ({ data }) => api.post(CREATE_NAL_TEST_ANALYSIS, { data }).then((response) => response?.data);

const createTestAnalysisItem = async ({ data }) => api.post(POST_NAL_TEST_ANALYSIS_ITEM, { data }).then((response) => response?.data);

const getTestAnalysisProcess = async () => await api.get(GET_NAL_TEST_ANALYSIS_PROCESS).then((response) => response?.data);

const deleteTestAnalysisItem = async (id) => await api.delete(DELETE_NAL_TEST_ANALYSIS_ITEM(id)).then((response) => response?.data?.data);

export { createTestAnalysis, getTestAnalysisProcess, getTestAnalysis, deleteTestAnalysisItem, createTestAnalysisItem };
