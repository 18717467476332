import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Row, Container, Form, Label, Spinner } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import FormInput from "../../Common/Form/FormInput";
import FormTextarea from "../../Common/Form/FormTextarea";
import FormSelect from "../../Common/Form/FormSelect";
import FormUpload from "../../Common/Form/FormUpload";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import APP_ROUTES from "src/routes/routes";
import { FORM_TYPE, UPLOAD_TYPE } from "src/constant/common";

export const FORM_NAME = {
  NAME: "name",
  VERSION: "version_name",
  NOTE: "note",
  SERIES: "series",
};

export const DEFAULT_DATA_PARAMS = {
  serie_name: "",
  serie_file_name: [],
};

const GNCSimulatorForm = ({ submitData, data, title, getRedirectURL = () => APP_ROUTES.GNC_SIMULATOR, versions }) => {
  const { t } = useTranslation("gnc-simulator");
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  let [searchParams] = useSearchParams();
  const formAction = searchParams.get("action");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm({
    defaultValues: data,
  });

  const { fields, update } = useFieldArray({
    control,
    name: "series",
  });

  const handleSubmitForm = (data) => {
    setIsSubmit(true);
    return submitData({ data }, () => setIsSubmit(false));
  };

  const handleUpdateAttachments = (serie_file_name, index) => {
    update(index, {
      serie_name: watch(`series.${index}.serie_name`),
      serie_file_name: serie_file_name?.map((i) => i),
    });

    clearErrors(`series.${index}.serie_file_name`);
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={getRedirectURL()} className="link-primary">
            {t("list.title")}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{title || t("new.title")}</BreadcrumbItem>
      </Breadcrumb>

      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="header-title">{title || t("new.title")}</div>
              <Form className="p-a-15">
                <div className="row">
                  <div className="col-md-6">
                    <FormInput
                      register={register}
                      label={t("new.name")}
                      name={FORM_NAME.NAME}
                      placeholder={t("new.name_placeholder")}
                      classInput="width-by-percent-80"
                      errors={errors?.[FORM_NAME.NAME]?.message}
                      requiredMessage={t("new.name_required")}
                      required
                      maxLength={255}
                      maxLengthMessage={t("new.name_maxlength")}
                    />

                    <FormSelect
                      label={t("new.version")}
                      name={FORM_NAME.VERSION}
                      placeholder={t("new.version_placeholder")}
                      classInput="width-by-percent-80"
                      control={control}
                      errors={errors?.[FORM_NAME.VERSION]?.message}
                      required
                      options={versions || []}
                      requiredMessage={t("new.version_required")}
                    />

                    <FormTextarea
                      register={register}
                      label={t("new.note")}
                      name={FORM_NAME.NOTE}
                      placeholder={t("new.note_placeholder")}
                      classInput="width-by-percent-80"
                      rows={4}
                    />
                  </div>

                  <div className="col-md-6">
                    <Label className="position-relative required-input">{t("new.params_series")}</Label>

                    {fields?.map((param, index) => (
                      <div className="d-flex gap-2" key={param.id}>
                        <div className="width-by-percent-80">
                          {/* <FormInput
                            register={register}
                            errors={errors?.series?.[index]?.serie_name?.message}
                            required
                            name={`series.${index}.serie_name`}
                            placeholder={t("new.params_series_name_placeholder")}
                            classInput="width-by-percent-100"
                            classGroup="m-b-7"
                            requiredMessage={t("new.params_series_name_required")}
                            errorsRelative
                          /> */}

                          <FormUpload
                            register={register}
                            files={param.serie_file_name}
                            name={`series.${index}.serie_file_name`}
                            handleUpdateAttachments={(attachments) => handleUpdateAttachments(attachments, index)}
                            onUpload={setIsUpload}
                            isMultiple={false}
                            useNewApiUpload
                            uploadType={UPLOAD_TYPE.GNC}
                            errorMessage={errors?.series?.[index]?.serie_file_name?.message}
                            validate={(files) => files.length > 0 || t("new.required_attachments")}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="d-flex justify-content-center gap-2">
                  <span
                    role="button"
                    className="btn btn-secondary width-by-px-200 height-by-px-38"
                    onClick={() => navigate(getRedirectURL())}
                  >
                    <span>{t("new.cancel")}</span>
                  </span>
                  <button
                    className="btn btn-primary width-by-px-200 height-by-px-38"
                    onClick={handleSubmit((data) => handleSubmitForm(data))}
                    disabled={isSubmit || isUpload}
                  >
                    {isSubmit ? <Spinner size="sm" /> : <span>{formAction === FORM_TYPE.EDIT ? t("new.retry") : t("new.submit")}</span>}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default GNCSimulatorForm;
