import React, { useState, Fragment, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import LoadingWrapper from "./Loading";
import { Collapse } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { PAGINATION_DEFAULT } from "src/constant/common";

const renderCellData = (item, data, collapseRow, { parentIndex, parentRow }) => (
  <>{item?.formatter ? item.formatter(data[item.dataField], data, { ...collapseRow, parentIndex, parentRow }) : data[item.dataField]}</>
);

const TableRow = ({ columns, rowHaveCollapse, data, openIds, setOpenIds, parentRow }) => {
  const isCollapse = useMemo(() => openIds.includes(data?.id), [openIds, data]);
  const collapseProps = rowHaveCollapse
    ? {
        isCollapse,
        toggleCollapse: (callback) => {
          if (openIds.some((id) => id === data.id)) {
            return setOpenIds((oldVal) => [...oldVal.filter((id) => id !== data.id)]);
          }
          setOpenIds((oldVal) => [...oldVal, data.id]);
          callback?.();
        },
        setCollapse: (status) => {
          if (status) {
            if (isCollapse) return;
            return setOpenIds((oldVal) => [...oldVal, data.id]);
          }
          return setOpenIds((oldVal) => [...oldVal.filter((id) => id !== data.id)]);
        },
      }
    : null;

  return (
    <>
      <tr id={`row${data?.id}`}>
        {columns.map((item, parentIndex) => (
          <td className={item?.rowClass} key={parentIndex}>
            {renderCellData(item, data, collapseProps, { parentIndex, parentRow })}
          </td>
        ))}
      </tr>
      {rowHaveCollapse?.collapseRow ? (
        <tr>
          <th colSpan={columns?.length} className="p-0 fw-normal none-border">
            <Collapse isOpen={isCollapse}>{rowHaveCollapse.collapseRow(data, { isCollapse })}</Collapse>
          </th>
        </tr>
      ) : (
        <tr>
          <th colSpan={columns?.length} className="p-0 fw-normal none-border"></th>
        </tr>
      )}
    </>
  );
};

const RenderDataTable = ({ data, columns, loading, pageCurrent, parentRow, t }) => {
  const [openIds, setOpenIds] = useState([]);

  useEffect(() => {
    setOpenIds([]);
  }, [pageCurrent]);

  return (
    <>
      {data?.length > 0 ? (
        data.map((rowData, indexRow) => {
          const rowHaveCollapse = columns?.find((c) => c?.collapseRow);
          return (
            <Fragment key={indexRow}>
              <TableRow
                columns={columns}
                rowHaveCollapse={rowHaveCollapse}
                data={rowData}
                pageCurrent={pageCurrent}
                openIds={openIds}
                setOpenIds={setOpenIds}
                parentRow={parentRow}
              />
            </Fragment>
          );
        })
      ) : (
        <tr>
          <td className="text-center" colSpan={columns.length}>
            {!loading ? <span>{t("no_data")}</span> : <span className="opacity-0">{t("no_data")}</span>}
          </td>
        </tr>
      )}
    </>
  );
};

const TableCommon = ({
  columns,
  onChangePage,
  classTableHead = "",
  striped = false,
  bordered = false,
  loading = false,
  data = [],
  customClassName = "",
  tableClassName = "",
  pageRangeDisplayed = 5,
  marginPagesDisplayed = 2,
  pageCount = 1,
  pageCurrent,
  hasPagination = true,
  tableFixed = false,
  totalItem,
  pageSize = PAGINATION_DEFAULT.pageSize,
  parentRow,
  headerWrapDiv = false,
}) => {
  const { t } = useTranslation("common");
  return (
    <LoadingWrapper loading={loading}>
      <div className={classNames("react-bootstrap-table", customClassName)}>
        <table
          className={classNames("table", tableClassName, {
            "table-striped-a": striped,
            "table-bordered": bordered,
            "table-fixed": tableFixed,
          })}
        >
          <thead className={classTableHead}>
            <tr>
              {columns?.map((item, index) => {
                return (
                  <th className={item.headerClass} key={index}>
                    {headerWrapDiv ? <div className={item.headerClass}>{item?.text}</div> : <>{item?.text}</>}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <RenderDataTable data={data} columns={columns} loading={loading} pageCurrent={pageCurrent} t={t} parentRow={parentRow} />
          </tbody>
        </table>
      </div>
      {hasPagination && pageCount > 1 && (
        <div className="pagination-custom">
          <div className="fw-bold">
            {data.length > 0 &&
              t("pagination", {
                first: pageCurrent * pageSize + 1,
                last: totalItem < (pageCurrent + 1) * pageSize ? totalItem : (pageCurrent + 1) * pageSize,
                total: totalItem,
              })}
          </div>
          <ReactPaginate
            nextLabel={">"}
            onPageChange={(value) => {
              onChangePage(value.selected + 1);
            }}
            {...{
              pageRangeDisplayed,
              marginPagesDisplayed,
              pageCount,
              forcePage: pageCurrent,
            }}
            previousLabel={"<"}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel={"..."}
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </LoadingWrapper>
  );
};

TableCommon.propTypes = {
  data: PropTypes.array,
};

export default TableCommon;
